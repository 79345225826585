/*!
 * Start Bootstrap - Creative v5.1.8 (https://startbootstrap.com/template-overviews/creative)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-creative/blob/master/LICENSE)
 */

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #f4623a;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #06EFEF;
  --gray-dark: #343a40;
  --primary: #f4623a;
  --secondary: #06EFEF;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;

}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #f4623a;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #d6370c;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #06EFEF;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #06EFEF;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #06EFEF;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1060px;
  }
}


@media (min-width: 1092px) {
  .container {
    max-width: 1060px;
  }
}



@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fcd3c8;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f9ad99;
}

.table-hover .table-primary:hover {
  background-color: #fbc0b0;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #fbc0b0;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #fbc2b3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 98, 58, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #06EFEF;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #06EFEF;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #06EFEF;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #06EFEF;
  opacity: 1;
}

.form-control::placeholder {
  color: #06EFEF;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #06EFEF;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 98, 58, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.35;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #002028;
  border-color: #002028;
}

.btn-primary:hover {
  color: #fff;
  background-color: #06375F;
  border-color: #06375F;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 122, 88, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ee3e0d;
  border-color: #e23a0d;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 122, 88, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #06EFEF;
  border-color: #06EFEF;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #06EFEF;
  border-color: #06EFEF;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #002028;
  border-color: #002028;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #f4623a;
  border-color: #f4623a;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 98, 58, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #f4623a;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 98, 58, 0.5);
}

.btn-outline-secondary {
  color: #06EFEF;
  border-color: #06EFEF;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #06EFEF;
  border-color: #06EFEF;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #06EFEF;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #06EFEF;
  border-color: #06EFEF;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #f4623a;
  text-decoration: none;
}

.btn-link:hover {
  color: #d6370c;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #06EFEF;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: white !important;
  text-decoration: none;
  background-color: #27dcdb;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #f4623a;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #06EFEF;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #06EFEF;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f4623a;
  background-color: #f4623a;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 98, 58, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fbc2b3;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fde9e3;
  border-color: #fde9e3;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #06EFEF;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #f4623a;
  background-color: #f4623a;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(244, 98, 58, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(244, 98, 58, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(244, 98, 58, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(244, 98, 58, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #fbc2b3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 98, 58, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #06EFEF;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #fbc2b3;
  box-shadow: 0 0 0 0.2rem rgba(244, 98, 58, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(244, 98, 58, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(244, 98, 58, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(244, 98, 58, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #f4623a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #fde9e3;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #f4623a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #fde9e3;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #f4623a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #fde9e3;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #06EFEF;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #06EFEF;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f4623a;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #06EFEF;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #06EFEF;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #f4623a;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #d6370c;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 98, 58, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}

.page-item.disabled .page-link {
  color: #06EFEF;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #f4623a;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #ee3e0d;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 98, 58, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #06EFEF;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #7f331e;
  background-color: #fde0d8;
  border-color: #fcd3c8;
}

.alert-primary hr {
  border-top-color: #fbc0b0;
}

.alert-primary .alert-link {
  color: #562214;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #f4623a;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #06EFEF;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #f4623a;
  border-color: #f4623a;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #7f331e;
  background-color: #fcd3c8;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #7f331e;
  background-color: #fbc0b0;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #7f331e;
  border-color: #7f331e;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #06EFEF;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1040px;
  }
  .modal-lg {
    max-width: 800px;
  }  
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Merriweather", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Merriweather", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #002028 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ee3e0d !important;
}

.bg-secondary {
  background-color: #06EFEF !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #f4623a !important;
}

.border-secondary {
  border-color: #06EFEF !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #f4623a !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #d6370c !important;
}

.text-secondary {
  color: #06EFEF !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
      color: #002028 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #06375F !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #06EFEF !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

body,
html {
  width: 100%;
  height: 100%;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75);
}

hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: #f4623a;
}

hr.light {
  border-color: #fff;
}

.btn {
  font-family: 'Roboto', Arial, sans-serif !important;
}

.btn-xl {
  padding: 1.25rem 2.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 10rem;
}

.page-section {
  padding: 10rem 0;
}

#mainNav {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: #fff;
  transition: background-color 0.2s ease;
}

#mainNav .navbar-brand {
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #212529;
}

#mainNav .navbar-nav .nav-item .nav-link {
  color: #06EFEF;
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 0.9rem;
  padding: 0.75rem 0;
}

#mainNav .navbar-nav .nav-item .nav-link:hover, #mainNav .navbar-nav .nav-item .nav-link:active {
  color: #f4623a;
}

#mainNav .navbar-nav .nav-item .nav-link.active {
  color: #f4623a !important;
}

@media (min-width: 992px) {
  #mainNav {
    box-shadow: none;
    /*background-color: transparent;*/
  }
  #mainNav .navbar-brand {
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-brand:hover {
    color: #fff;
  }
  #mainNav .navbar-nav .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.7);
    padding: 0 1rem;
  }
  #mainNav .navbar-nav .nav-item .nav-link:hover {
    color: #fff;
  }
  #mainNav .navbar-nav .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  #mainNav.navbar-scrolled {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
  #mainNav.navbar-scrolled .navbar-brand {
    color: #212529;
  }
  #mainNav.navbar-scrolled .navbar-brand:hover {
    color: #f4623a;
  }
  #mainNav.navbar-scrolled .navbar-nav .nav-item .nav-link {
    color: #212529;
  }
  #mainNav.navbar-scrolled .navbar-nav .nav-item .nav-link:hover {
    color: #f4623a;
  }
}

header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 72px);
  background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url("../img/bg-masthead.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

header.masthead h1 {
  font-size: 2.25rem;
}

.p_outside{
  background-color: #06375F;;
  padding: 20px 20px;
  a{
    color:white;
    border-bottom: 1px dotted white;
  }
  a:hover{
    color: white;
    opacity: .9;
    text-decoration: none;
  }

}
.column-left{
  img{
    width: 100%;
    background-color: white;
    margin-bottom: 40px;
  }
}

@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 40rem;
    padding-top: 72px;
    padding-bottom: 0;
  }
  header.masthead p {
    font-size: 1.15rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }


}

@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 3.5rem;
  }
}

#portfolio .container-fluid {
  max-width: 1920px;
}

#portfolio .container-fluid .portfolio-box {
  position: relative;
  display: block;
}

#portfolio .container-fluid .portfolio-box .portfolio-box-caption {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  opacity: 0;
  color: #fff;
  background: rgba(244, 98, 58, 0.9);
  transition: opacity 0.25s ease;
  text-align: center;
}

#portfolio .container-fluid .portfolio-box .portfolio-box-caption .project-category {
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
}

#portfolio .container-fluid .portfolio-box .portfolio-box-caption .project-name {
  font-size: 1.2rem;
}

#portfolio .container-fluid .portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}

/* NEW STYLES */

body {

    font-size: .9rem;
    color: #fff;
}

footer{
  color: #fff;
}

.bg-light {
    background-color: white !important;
}

/* form */

.material {
    position: relative;
    padding: 0;
    margin: 5px 0px;
    border: none;
    overflow: visible;
    margin-bottom: 30px;
}
.material input {
  box-sizing: border-box;
  width: 100%;
  padding: 12px 0px 8px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #DDD;
  font-size: 100%;
  outline: none;
  cursor: text;
  color: var(--secodary);
  padding-left: 18px;
}
.material input::-webkit-input-placeholder {
  transition: color 300ms ease;
}
.material input:not(:focus)::-webkit-input-placeholder {
  color: transparent;
}
.material hr {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 2px;
  border: none;
  background: #06375F;
  font-size: 1px;
  will-change: transform, visibility;
  transition: all 200ms ease-out;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  visibility: hidden;
  z-index: 10;
}


/*.material input:focus ~ hr {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  visibility: visible;
}

*/


.material label {
  position: absolute;
  top: 10px;
  left: 18px;
  font-size: 100%;
  color: #002028;
  -webkit-transform-origin: 0 -150%;
          transform-origin: 0 -150%;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  pointer-events: none;
  color: #002028;
}


.material input:focus ~ label,
.material input:valid ~ label {
visibility: hidden;
}



.btn {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 20px;
}

.btn-info {
    color: #fff;
    background-color: #002028;
    border-color: #002028;
}

.btn-info:hover {
    color: #fff;
    background-color: #06375F;
    border-color: #06375F;
}

.page-section {
    padding: 10rem 0 0;
}

.text-info {
        color: #002028 !important;
}

.card{
  border:none;
  // border-bottom: 6px solid;
  // border-image: linear-gradient(to right, #002028 0%,#002028 33%,#ffc424 33%,#ffc424 67%,#002028 67%,#002028 40%) 1 stretch repeat; /* W3C */
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important;
}

.bg-primary {
        background-color: #002028 !important;
}

.text-white {
    color: #fff !important;
}

#mainNav {
    box-shadow: none;
    background-color: white;
}

div.form-group {
  transition:background-color 1s;
}
div.form-group.form-error {
  // background-color:white;
  // background-color:rgba(255,0,0,0.3);
  div{
    color: #e30000;
    margin-top: -25px;
    margin-left: 2px;
    font-size: 12px;
  }
}

.alert-warning {
    color: #e30000;
    background-color: #fff3cd;
    border-color: #f7000024;
    background-color: #f7000024;
}

a {
    color: #002028;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #002028;
    // text-decoration: none;
    background-color: transparent;
}

.btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #002028;
    border-color: #002028;
}

.checkbox {

  width: auto;
  opacity: .5;
  position: absolute;
  right: 0px;
  top: 8px;
  z-index: 999;
  background: blue;
  opacity: .5;
    // top: 8px;
    z-index: 999;
    right: 10px;
    margin-top: 0px !important;

  input{
      height: auto;
      opacity: 0;
      position: absolute;
      top: 10px;
      right: 0px;
      width: 28px;
    /* background: red !important; */
    cursor: pointer;
  }

  label{
    position: absolute;
    width: 28px;
    height: 28px;
    margin-top: 15px;
    left: -20px !important;
    z-index: 999;
  }

  img{
    width: 100%;
    // background: red;
  }
}

.logo{
  max-width: 250px;
}

.logo-right {
    max-height: 90px;
    float: right;
}

.help-content{
  padding: 10px;
   a.btn.btn-secondary {
    width: 100% !important;
}
.nopadding{
  padding: 0px !important;
}
}

/* select starting stylings ------------------------------*/
.select {
  position: relative;
  width: 100%;
}

.select-text {
  // position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  padding: 10px 10px 8px 0;
  font-size: 120%;
  color: var(--secodary); border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.12);
}

/* Remove focus */
.select-text:focus {
  outline: none;
  border-bottom: 1px solid rgba(0,0,0, 0);
}

  /* Use custom arrow */
.select .select-text {
  appearance: none;
  -webkit-appearance:none
}

.select:after {
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.12);
  pointer-events: none;
}


/* LABEL ======================================= */
.select-label {
  color: rgba(0,0,0, 0.26);
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: 0.2s ease all;
}

/* active state */
.select-text:focus ~ .select-label, .select-text:valid ~ .select-label {
  color: #002028;
  top: 5px;
  transition: 0.2s ease all;
  transform: scale(.7);
}

/* BOTTOM BARS ================================= */
.select-bar {
  position: relative;
  display: block;
  width: 100%;
}

.select-bar:before, .select-bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #002028;
  transition: 0.2s ease all;
}

.select-bar:before {
  left: 50%;
}

.select-bar:after {
  right: 50%;
}

/* active state */
.select-text:focus ~ .select-bar:before, .select-text:focus ~ .select-bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.select-highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}


// LANDER

.content-lander{

  .btn {
      padding: 10px 35px;
      border-radius: 0px;
  }

  a.btn.btn-secondary {
      width: auto;
      border-radius: 0px;
      background-color: #002028;
      font-weight: bold;
      font-size: 16px;
      border: none;


      &:hover {
            background-color: #06375F;
            transition: all .5s;
        }
  }

  .copyright, .copyright a {
      color: #8d8d8d;
  }

  .copyright {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 12px;
      border-top: 1px solid #D8D8D8;
      background-color: #FFFFFF;
  }

  .copyright li {
      padding-left: 10px;
      padding-right: 10px;
      float: left;
  }

  /* Banner */
  .banner{
    height: 500px;
    background-size: cover;
    background-position: center;
  }

  .banner h1{
    color: white;
    font-weight: bold;
  }

  .banner p{
    color: white;
    font-size: 18px;
    line-height: 1.3;
    margin: 40px 0px;
  }

  .navbar-default {
      border: none;
  }

  .vcenter {
    display: flex;
      align-items: center;
  }

  .navbar-nav>li>a {
      padding-top: 0px;
      padding-bottom: 0px;
      padding: 0px 12px;
  }
  .navbar-brand img{
    float: left;
  }
  .navbar-brand h4 {
      float: left;
      line-height: 81px;
      padding: 0px;
      margin: 0px 0px 0px 20px;
      font-weight: bold;
  }

  .benefits{
    padding: 80px 0px;
    background-image: url(../images/bk.png);
    background-size: cover;
    background-position: top;

  }

  .benefits div{
    padding: 0px 40px;
  }

  .benefits h3{
    font-weight: bold;
    margin-bottom: 40px;
  }

  .benefits p{
    font-size: 18px;
    margin-bottom: 40px;
  }

  .contact {
      padding: 80px 0px;
  }

  .contact div{
      padding: 0px 40px;
  }

  .contact p, .contact a{
      font-size: 16px;
  }

  .contact i{
      font-size: 14px !important;
  }

  .contact .apps img{
      max-height: 52px;
      padding: 5px;
  }


  .contact h5{
    font-weight: bold;
  }

  .tos{
    margin: 60px auto;
  }
  .tos p{
    font-size: 14px !important;
  }

  .tos h1, .tos h2, .tos h3, .tos h4 {
    font-weight: bold;
    margin: 30px 0px 15px 0px;
  }
}

#mainNav {
    // padding-bottom: 0px !important;
    // padding-top: 0px !important;
}
.navbar-brand img{
max-height:54px;
width: auto !important;
margin-left: 24px;
// float:left;
}

.navbar-brand a{
    padding: 0px;
    color: #002028 !important;
    font-size: 30px;
    font-weight: 500;
    height: auto;
    line-height: normal;
}
.navbar-brand h4 {
    float: left;
    line-height: 81px;
    padding: 0px;
    margin: 0px 0px 0px 20px;
    font-weight: bold;
    color: #002028 !important;
}

.logo-right {
    max-height: 54px !important;
    max-width: 250px;
}


.login {
    color: #002028 !important;

    &:hover {
        opacity: 0.7;
    }
}

.btn_nav:hover {
        opacity: 0.7;
    } 














body {
  font: 14px/24px Arial, Helvetica, sans-serif;
  color: #0f0f0f;
  background-image: url('../images/bg_signin.jpg') !important;
  background-size: cover !important;
  font-family: 'Roboto', Arial, sans-serif !important;
  background: fixed;

}

/* Standard Styles Starts */

a { color: #002028; }
a:hover,
a:focus {
  color: #06375F;
  /* opacity: .8; */
  text-decoration: none;
}
a:focus { outline: none; }

/* Heading Styles Starts */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', Arial, sans-serif !important;
}
h1 { font-size: 45px; }
h2 { font-size: 42px; }
h3 { font-size: 36px; }
h4 { font-size: 24px; }
h5 { font-size: 18px; }
h6 { font-size: 16px; }


/* NEW STYLES */
body, a, li, div, button {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

footer.py-5 {
  display: none;
}

#nav.navbar {
  border-bottom: 0px solid #D8D8D8;
}
#mainNav{
  padding-left: 1rem!important;
}

nav#nav {
    padding: 0px;
}

a.navbar-brand {
    margin: 0px;
    padding: 0px;
}

.navbar>.container, .navbar>.container-fluid {
    padding: 0px;
}
.navbar-nav {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
}


#nav.navbar-default .navbar-nav > li > a {
    font-size: 13px;
    font-weight: 800;
    color: #313131;
    line-height: normal;
    line-height: 81px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding: 0px 12px;
}

#nav.navbar-default .navbar-nav >li {
    float: left;
}

.navbar-nav li a.tel {
    color: #002028 !important;
    font-size: 15px !important;
    line-height: normal;
}
#menu-toggle {
  display: none;
}

.logo-signin {
    width: 400px;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 60px;

}
.page-section {
    padding: 1rem 0 0;
}

.material input {
    border-radius: 24px;
}
input:-internal-autofill-selected {
    color: #002028 !important;
}

.btn-signin {
    background-color: transparent;
    border-color: white;
    border-radius: 24px;
    margin-top: 20px;

    &:hover {
        background-color: #06EFEF;
        border-color: #06EFEF;
    }
}


.material {
    margin: 5px 0 20px;
}

.forgot-password {
    float: right;
    color: white;
    border-color: transparent;
    background-color: transparent;
      padding-top: 0;

    &:hover {
        color: #aaa;
    }    
}
.signin-container {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
    margin-top: 70px;
}
.form-check-input {
    margin-left: 2px;
    font-size: 16px;
}
.form-check-label {
    margin-bottom: 0;
    float: left;
    margin-left: 24px;
    color: white;
    margin-top: 1px
}
.material-password {
    margin-bottom: 10px;
}
nav#nav {
    background-color: #002028;
    border-bottom: 10px solid #fff !important;   
    padding-bottom: 12px;
    padding-top: 12px;
}
.bg-header {
    height: 200px;
    background-image: url(../images/header_01.jpg)!important;
    background-size: cover;
    background-position-y: center;
    border-bottom: 3px solid white;
    padding: 70px;
    padding-left: 44px;    
    color: white;
}
#nav.navbar-default .navbar-nav>li>a {
  color: white;
}
span.nombre-signin {
    color: white;
    font-size: 18px;
    padding-right: 18px;
}
.container_icons {
    float: left;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 34px;
}
.title_icons {
    text-align: center;
    display: block;
    margin-top: 14px;
    font-size: 18px;
}

.image_icons {
    width: 94px;
    text-align: center;
    display: block;
    margin: 0 auto;
}
.page-section {
    padding: 1rem 0 0;
    padding-bottom: 40px;
    min-height: 100%;
    width: 100%;
    position: absolute;

}
.section_icons{
    background-image: url(../images/pattern_02.png)!important;
    background-size: contain;
    background-repeat: repeat-y;
    background-position-y: center;
}
.card {
    margin: 20px auto;
    margin-bottom: 32px;
    margin-top: 12px;
}
.activity_title_row {
    display: inline-flex;
    margin-top: 8px;
}

.chevron {
    font-size: 22px;
    color: #06EFEF;
    margin-right: 4px;
    margin-top: 4px;
}
.activity_title {
    font-size: 22px;
    color: #002028;
    font-weight: 600;
}



.btn-card-reservar {
    font-size: 13px;
    border-radius: 20px;
    padding: 6px 16px;
    box-shadow: 0 2px 6px grey !important;
    margin-top: 16px;

  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;


    &:hover {
      background-color: #06EFEF;
      border-color: #06EFEF;
      transform: translate(0px, 1px);
     box-shadow: 0 0px 1px grey !important;      
    }

}

.activity_time {
    margin-top: 20px;
    color: #6c6c6c;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}

.activity_type, .activity_occupancy {
    margin-bottom: 0px;
}
.activity_occupancy {
    color: #002028;
    margin-bottom: 10px;
}

.sub_container_icons:hover {
    opacity: 0.6
  }
.activity_place {
    margin-bottom: 0px;
    font-weight: 500;
    color: #a0a0a0;
}
.col_activity_info {
    padding-right: 0;
flex: 0 0 40.33333%;
    max-width: 40.33333%;  
}
.col-activity_line {
      padding: 0;
    -ms-flex: 0 0 1.33333%;
    flex: 0 0 1.33333%;
    max-width: 1.33333%;
    border-left: 3px solid #68f0ef;
    margin-bottom: 4px;
}

.container_group_icons {
    padding-left: 90px;
    padding-right: 90px;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #68f0ef;
    border-color: #68f0ef;
}
a:focus, a:hover {
    color: white;
}
.activity_today_date {
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 6px;
    padding-top: 6px;
    width: 100%;
    margin-bottom: 0;
}

.activity_header {
  margin: 0 auto;
}
.activity_tomorrow_date {
    right: 0;
    position: absolute;
    margin-top: 10px;
    color: white;
    margin-bottom: 0;

    &:hover {
      text-decoration: underline;
    }
}
.activity_btn_back {
    left: 0;
    position: absolute;
    margin-top: 10px;
    color: white;

    &:hover {
      text-decoration: underline;
    }
}

.activity_btn_back:before {
  content: "< ";
}
.activity_tomorrow_date:after {
  content: " >";
}
.activity_card_none .activity_title {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
}
.register_btn {
    color: white;
    border-color: transparent;
    background-color: transparent;
      padding-top: 0;

    &:hover {
        color: #aaa;
    }    
}

.modal-header {
    background-color: #002028;

    h3 {
        color: #fff;
        text-align: center;
        font-size: 24px;
    }
}

.modal-content {
     border: none;
}


.modal-dialog{
    border: none;
    box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23)!important;
    margin-top: 10%;

    .activity_type {
        font-size: 18px;
    }
    .activity_place {
        font-size: 16px;
    }
    .col_activity_info {
        padding-left: 26px;
    }    
    .activity_time {
        font-size: 22px;
    }    
    .activity_title_date {
        margin-left: 10px;
        font-size: 18px;
        font-weight: 300;
        margin-bottom: 6px;
        margin-top: 6px;
        margin-left: 28px;
    }
}


.modal-backdrop.show {
    opacity: .7;
}
.btn_modal_cancelar {
    border: 1px solid #002028;
}
.btn_modal_cancelar:hover {
    opacity: 0.5;
    border: 1px solid #002028;
    background-color: white;
}

.btn_modal_cancelar, .btn_modal_confirmar {
    margin: 0px 42px !important;
}

.col_time {
    float: right;
    margin-top: -68px;
    padding-right: 20px;
}

#modal-title {
    margin-left: 10px;
}



/* ///////// SPINNER /////////// */
.fadein {
  display: flex;
  justify-content: center;
  align-items: center;  
  position: fixed;
  margin: 0;
  padding: 0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: #152e51f7;
  z-index: 99999;

}
.fadein img {
    -webkit-animation-name: fade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 1.2s;
    -moz-animation-name: fade;
    -moz-animation-iteration-count: infinite;
    -moz-animation-duration: 1.2s;  
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
}

@-webkit-keyframes fade {
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}
@-moz-keyframes fade {
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}
@keyframes fade {
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}


/* ///////// END SPINNER /////////// */



.activity_age_group, .activity_reserve_user {
    font-weight: 300;
}

#toast-container.toast-bottom-full-width>div, #toast-container.toast-top-full-width>div {
    min-width: 100%!important;
    bottom: 0!important;
    position: fixed!important;
    display: block!important;
    box-shadow: none!important;
    margin-bottom: 0!important;
    text-align: center;
    background-image: none !important;
}


/*

div.form-group.form-error div {
    position: absolute;
    color: #f30000;
    margin-top: -27px;
    font-size: 13px;
    width: 300px;
    border-radius: 14px;
    padding-left: 12px;
    font-weight: 600;
    text-align: right;
    left: -130px;
}

*/


.signup-container,  .signin-container{
    padding-bottom: 0 !important;
}
.btn_menu {
    color: white;

    &:hover {

      color: white;
      opacity: .7;
    }
}

.menu_user_name {
    padding-left: 16px;
    border-left: 2px solid #68f0ef;
}
.fa-user {
    padding-right: 10px;
    font-size: 20px;
}

.dropdown-menu {
    left: 48px;
}
.dropdown-menu {
    left: 48px;
}
.dropdown-divider {
    margin: 2px 0;
}
.dropdown-item {
    padding: .35rem 1.5rem;
}
.dropdown-item.active, .dropdown-item:active {
    color: #002028;
    text-decoration: none;
    background-color: none;
}





.btn-card-cancelar {
    background-color: #511515;
    border-color: #511515;
    border-radius: 20px !important;
    width: 98px;    

    &:hover {
    background-color: #a51d1d;
    border-color: #a51d1d;      
    }

    &:active{
    background-color: #a51d1d !important;
    border-color: #a51d1d !important;        
    }
}
.history_reserved, .history_canceled {
    font-weight: 500;
    color: #002028;
    font-size: 15px;
}

.select_history {
    padding: 3px 32px 3px 8px!important;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #002028!important;
}

.dropdown_icon {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    float: left;
    margin-bottom: 0;
    margin-top: 6px;
}

.dropdown_icon:before {
    content: "\f0dd";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    font-style: normal;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    color: #002028;
    position: absolute;
    right: 15px;
    top: 1px;
    text-shadow: 0 1px 0 #fff;
    pointer-events: none;
    font-size: 20px;
}
.btn_historial_reservas{
    color: #fff;
    float: right;
    margin-top: 16px;

  &:hover {
    text-decoration:underline;
  }
}




.select_global {
    padding: 3px 32px 3px 8px!important;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #002028!important;
}
.dropdown_icon_global {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-bottom: 14px;
    margin-top: 6px;
}

.dropdown_icon_global:before {
    content: "\f0dd";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    font-style: normal;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    color: #002028;
    position: absolute;
    right: 15px;
    top: 1px;
    text-shadow: 0 1px 0 #fff;
    pointer-events: none;
    font-size: 20px;
}



.dropdown_icon_right {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    float: right;
    margin-right: 30px;
    margin-bottom: 0;
}

.dropdown_icon_right:before {
    content: "\f0dd";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    font-style: normal;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    color: #002028;
    position: absolute;
    right: 15px;
    top: 1px;
    text-shadow: 0 1px 0 #fff;
    pointer-events: none;
    font-size: 20px;
}
.fa-clock {
    color: #68f0ef;
    margin-right: 4px;
}


.historial thead {
    background-color: #3287b8;
}

.historial tbody {
  color: #002028;
  background-color: white;
}

.historial td {
    font-weight: 300;
}
.historial th {
    font-weight: 500;
}

.historial .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #d4d4d400;
}
.historial tbody td, .historial tbody th {
    border-bottom: 1px solid #e6e6e6 !important;
}

.mi_perfil_data {
      font-size: 17px;
    margin-bottom: 14px;
    color: #002028;
    border-bottom: 1px solid #68f0ef;
    padding-bottom: 12px;

    span {
      font-weight: 300;
      color: #555;    
    }
}


.mi_perfil .col-activity_line {
    padding: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    border-left: 3px solid #68f0ef;
    margin-bottom: 14px;
}
.btn_editar {
    font-size: 20px;
    padding: 9px!important;
    width: 50px!important;
    border-radius: 30px!important;
    margin: 0;
    position: absolute;
    float: right;
    display: block;
    right: 28px;
    top: 12px;
}


.card_mi_perfil .mi_perfil_data:last-child, .card_grupo_familiar .mi_perfil_data:last-child {
    border-bottom: none !important;    
}

.modal_profile p {
    font-size: 15px;
    color: #002028;
    font-weight: 500;
    margin-bottom: -2px;
    margin-top: 22px;
}
.modal_profile input {
    border-bottom: 1px solid #ccc;
    border-left: none;
    border-right: none;
    border-top: none;
    width: 100%;
    font-weight: 300;
}
.modal_profile input:focus {
    outline-color: white !important;
    outline-width: 0px !important;
    outline-offset: 0px !important;
    outline: none !important;
  border-bottom: 1px solid #68f0ef;
  overflow: hidden;
}


.modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
}

.btn_reservar {
        color: #68f0ef;
    display: inline-flex;
    margin-top: 20px;
    margin-right: 12px;

    .fa-calendar-alt {
        font-size: 20px;
        margin-right: 10px;
    }

    p {
      color: white;
      font-size: 16px;
    }

    &:hover {
      opacity: 0.7;
    }
}

.header_right {
    display: inline-flex;
}

.activity_day {
  margin-bottom: 0px;
  margin-top: 10px;
  text-align: center;
}

button.close {
    font-size: 34px;
    margin-right: 14px;
    margin-top: 10px;
    color: #162f51;
    opacity: 1;

    &:hover {
      opacity: .4 !important;
    }
}

.modal_info img.image_icons {
    margin-bottom: 22px;
}
.modal_info {
    padding-left: 20px;
    padding-right: 20px;

}

  p {
    line-height: 1.4em;
}

.modal_info .col-sm-4.px-4 {
    border-right: 1px solid cyan;
}
.modal_info .col-sm-4.px-4:last-child {
    border-right: none;
}

.sub_btn_reservas_dropdown {
    padding: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: left;
    width: 100%;
    display: block;
    font-size: 15px;     

    &:hover {
          background-color: #162f51 !important;
          color: white !important;

    }

}

.reservas_dropdown .dropdown-menu {
    left: 100px;
    top: 12px;
    width: 198px;
    padding-bottom: 2px;
    padding-top: 2px;

}

.btn_reservas_dropdown::after {
    position: relative;
    font-size: 16px;
    top: 3px;
    margin-left: 6px;
}
input::-webkit-input-placeholder {
    color: #929292;
}

.card_covid p{
    font-size: 15px;
    padding-bottom: 28px;
    border-bottom: 1px solid #ddd;
}

.btn_covid {
    width: 50%;
    margin: 10px auto 4px;
    font-size: 14px;
}

.icon_exclamation {
    width: 120px;
    margin: 16px auto;
    margin-bottom: 30px;
}
.row_invitados {
    width: 100%;
    margin: 0;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #eaeaea;
    margin-right: 0;
}
.row_invitados input {
    width: 100%;
    margin-bottom: 12px;
}
.row_invitados p {
    margin-bottom: 10px;
}


.row_invitados input:focus {
    outline: 0;
    outline-offset: 0!important;
    border: 1px;
    border-bottom: 2px solid #0ff;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    display: none !important;
}


.welcome_container {
    color: #fff;
    text-align: center;
    margin-top: 70px;

    p {
      color: white;
      font-size: 18px;
      line-height: 1.35em;
    }

    h1 {
      color: white;
      font-size: 24px;
      margin-bottom: 20px;
    }

}

.container_signup, .container_welcome {
    text-align: center;

    h1 {
      color: white;
      font-size: 32px;
    }
    p {
    color: #fff;
    font-size: 18px;
    line-height: 1.35em;
    }
}
.container_welcome .btn-signin {
    width: 60%;
}
.col_welcome_left {
    padding-top: 10%;
    background-color: transparent;
}


.container_welcome .col_signup_left {
  padding-top: 12%;
  background-color: transparent;
}

.col_welcome_right {
  padding-top: 40px;
}

.col_welcome_right, .col_welcome_left  {
  float: left;
}






/* /////////////// TABS /////////////////*/

.tab-container {
  list-style-type: none;
  padding: 0;
  margin: 0;
    max-width: 100%;
  position: relative;
  background: #fff;
  ul { list-style-type: none; padding: 0; }
  .arrows { 
    display: block;
    width: 60px;
    font-size: 32px;    
    bottom: 10px;
    right: 0px;
    position: absolute;
    label { 
      display: inline-block; 
      cursor: pointer; 
      color: #aaa; 
      -webkit-user-select: none;
      -moz-user-select: none;    
      -ms-user-select: none;     
      user-select: none;  
      margin-left: 5px;      
      &:hover { color: #333; }
    }
  }
  .tab-content-container { 
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; 
    height: 0; 
    opacity: 0; 
    overflow: hidden; 
    line-height: 22px;
    background: #fff;
    position: absolute;
    top: 40px;
    padding: 0px 40px 0 20px;

    border-top: 0;
    box-shadow: 0 5px 5px -5px #333;  
    .tab-content {
      opacity: 0;
      padding: 15px 0;
      min-height: 185px;  
      transition: opacity 0.1s ease-in;    
    }
  }
  .tab {
    outline: 0;
    float: left;
    cursor: pointer;    
    opacity: 1;
    width: 50%;
    line-height: 40px;
    display: block;
    margin: 0;
    position: relative;
    background: #162f51;
    color: #fff;
    text-align: center;    
    font-size: 16px;

    &:hover { background: #365690; }          
  }
  .tab-toggle {
    display: none;
    &.active + .tab,
    &:checked + .tab { 
      background: #fff; 
      color: #333; 
    }
    &.active ~ .tab-content-container,
    &:checked ~ .tab-content-container { 
      height: auto;
      opacity: 1;
      min-height: 410px;
      .tab-content { 
        opacity: 1; 
        label { opacity: 1; }
      }
    }
  }
}

/* /////////////// ENDS TABS /////////////////*/





/* /////////////// ACCORDION /////////////////*/


    .accordion{
      transform: translateZ(0);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      background: #fff;
    }

    .accordion > .accordion-toggle{
      position: absolute;
      opacity: 0;
      display: none;
    }

    .accordion > label{
      position: relative;
      display: block;
      height: 50px;
      line-height: 50px;
      padding: 0;
      font-size: 14px;
      font-weight: 700;
      border-top: 1px solid #ddd;
      background: #fff;
      cursor: pointer;
      text-align: left;
      color: #162f51 ;
    }

    .accordion > label:after {
      content: '\f078';
      position: absolute;
      top: 0px;
      right: 20px;
      color: cyan;
        font-family: "Font Awesome 5 Free";
      transform: rotate(-90deg);
      transition: .3s transform;
    }

    .accordion > section{
      height: 0;
      transition: .3s all;
      overflow: hidden;
    }

    .accordion > .accordion-toggle:checked ~ label:after{
     transform: rotate(0deg);
    }

    .accordion > .accordion-toggle:checked ~ section{
      height: 200px;
    }

    .accordion > section p {
      margin: 5px 0;
      padding: 0;
      font-size: 16px;
      line-height: 1.25;
      color: #333;
      text-align: left !important;
    }

    .accordion img {
        width: 100%;
    }
    .accordion b {
        color: white;
        background-color: #162f51;
        border-radius: 20px;
        font-size: 13px;
        font-weight: 400;
        padding: 3px 6px;
        vertical-align: text-top;
    }
    /* /////////////// ENDS ACCORDION /////////////////*/






    .btn.focus, .btn:focus, .custom-control-input:focus~.custom-control-label::before {
        box-shadow: none !important;
    }
    .col_signup_left, .col_signup_right {
        float: left;
    }
    .col_signup_left {
        top: 160px;
    }


    div.form-group.form-error div {
        color: #e30000;
        position: relative;
        margin-top: -24px;
        margin-left: 2px;
        font-size: 12px;
        text-align: left;
    }

    .form-group.form-error {
        margin-bottom: 0;
    }

    .btn-info.disabled, .btn-info:disabled:hover {
        color: #fff;
        background-color: #002028;
        border-color: #002028;
    }

    .historial {
        padding-top: 34px;
    }
    .btn-card-cancelar {
        width: auto;
    }
    label.img-icon-input {
        visibility: visible !important;
        top: -14px;
        padding-right: 11px;
        opacity: 1;
    }
    .checkbox {
        opacity: 1;
        position: absolute!important;
        font-size: 11px !important;
        top: 10px;
    }
    .card_grupo_familiar .activity_title {
        font-size: 22px !important;
        color: #002028;
        font-weight: 600 !important;
    }
    .text-right a:hover {
        color: #002028;
        opacity: .6;
    }
    .text-right a {
        font-size: 12px;
    }
    .col_misreservas_time {
        padding-right: 2px;
        padding-left: 2px;
    }

    .activity_reserved_family_row {
      margin-top: -10px;
    }


    .footer {
        background-color: #081527;
        border-top: 3px solid #fff;
        display: block!important;
        position: absolute;
        width: 100%;
        margin: 20px auto 0 0;
        bottom: -60px;
        padding-top: 36px !important;
    }

    .footer_section_icons {
        margin-left: 0px;
    }

    .footer .container {
        display: flex;
        justify-content: space-between;
    }
    .footer .container a:hover {
        opacity: .7;
    }
    .table_historial {
      margin-bottom: 40px !important;
      margin-top: 40px;
    }
    .developed_singin, .developed_singup {
        color: #fff;
        position: absolute;
        bottom: 4px;
        right: 20px;
        opacity: .6;

        &:hover {
          opacity:1;
        }
    }

    .activity_reserved_family_row a:focus {
        color: #002028;
    }



    .dropdown-menu {
        position: absolute;
        right: 10px !important;
        left: unset !important;
    }
    .col_welcome_left {
        padding-top: 9%;
        background-color: transparent;
        float: unset;
        margin: 0 auto;
    }
    .modal_info h5 {
      color: #002028;
    }
    .modal_info a {
        font-weight: 600;

        &:hover {
          opacity:0.7;
            color: #002028;      
        }
    }

    .card.shadow-lg.col-md-9.col-lg-6.activity_card {
        display: grid;
    }
    .col_maintenance {
        margin: 0 auto;
        display: block;
        float: none;
        top: 120px;
    }
    .page-frontcategorias .logo-signin, .page-fronthorarios .logo-signin {
        margin-top: 0;
        margin-bottom: 16px;
        width: 200px;
    }

    .page-frontcategorias .container_group_icons {
        padding-left: 120px;
        padding-right: 120px;
    }
    .page-fronthorarios h3 {
        font-size: 24px;
        color: cyan;
    }
    .col-fronthorarios {
        padding-left: 0;
        padding-right: 0;
    }
    .page-fronthorarios h1 {
        font-size: 18px;
        color: #002028;
    }
    .page-fronthorarios h2 {
        font-size: 19px;
        color: #828282;
        font-weight: 400;
    }
    .page-fronthorarios h4 {
        font-size: 14px;
        font-weight: 500;
    }
    .page-fronthorarios .card_grid {
        background-color: #fff;
        padding-bottom: 2px;
        padding-top: 10px !important;
    }
    .page-fronthorarios h5 {
        color: #002028;
        font-weight: 300;
        font-size: 17px;
    }


    .page-fronthorarios .col-sm-2 {
        padding-left: 5px;
        padding-right: 5px; 
        -ms-flex: 0 0 14%;
        flex: 0 0 14%;
        max-width: 14%;
    }
    .container-btn_ver_horarios {
        text-align: right;
    }
    .btn_ver_horarios .fa-calendar-alt {
        font-size: 18px;
        margin-right: 10px;
    }

    .container_icons {
        min-height: 206px;
    }

    .page-fronthorarios .col-sm-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 14%;
        max-width: 14%;
    }
    .page-fronthorarios {
        padding-left: 2.5em!important;
        padding-right: 2.5em!important;
    }  

    .dropdown_icon_global {
        margin-bottom: 24px;
        margin-top: 0;
    }

    .page-fronthorarios .footer_section_icons {
        margin-left: 0px;
    }




    .separator {
      height: 24px;
      float: left;
  }






/*
////////////////////////////////////////////////  ENFOQUE ////////////////////////////////////////////////////////////////             
*/

body {
  font: 14px/24px Arial, Helvetica, sans-serif;
  color: #0f0f0f;
  background-image: none !important;
  background-size: cover !important;
  font-family: 'Roboto', Arial, sans-serif !important;
  background: fixed;
}

.page-fronthorarios h3, .btn_ver_horarios,   .activity_reserved_family_row a:focus, .modal_info h5, .activity_occupancy {
    color: #00b0db;
}
.btn_ver_horarios:hover, .btn_reservar:hover {
    opacity: .7;
    color: #00b0db;    
}


.container_signup p, .container_welcome p, .container_signup h1, .container_welcome h1, .forgot-password, .register_btn, .btn_historial_reservas, a, .btn_ver_horarios p, .btn_reservar p, .btn_menu, .title_icons, .activity_title, .activity_tomorrow_date, .activity_btn_back  {
    color: #002028;
}
.footer, .modal-header {
    background-color: #002028;  
}

.btn_historial_reservas:hover {
     color: #002028; 
}


.btn_menu:hover{
    opacity: .7;
    color: #002028;    
}

.btn_editar:active, .btn_editar:focus, .historial thead, .btn-info.disabled, .btn-info:disabled:hover, .btn-signin,.btn-signin:focus, .dropdown-item:focus, .dropdown-item:hover, .btn_editar, .btn-card-reservar {
    background-color: #00b0db !important;
}
.btn_editar:focus, .btn_editar:active, .btn-info.disabled, .btn-info:disabled:hover, .btn_editar,  .btn-card-reservar {
    border-color: #00b0db !important;
}

.btn-info.disabled, .btn-info:disabled:hover {
  opacity: .35;
}



button.close, i.far.fa-user, .fa-check-circle {
    color: #00b0db;
}



.fa-clock, .btn_reservar, .btn_ver_horarios{
  color: #00b0db;
}
.btn_ver_horarios:hover, .btn_reservar:hover {
    opacity: .7;
    color: #00b0db;    
}



.btn-signin:hover, .btn_editar:hover, .btn-card-reservar:hover {
    opacity: .7;
    background-color: #00b0db;
}
.btn_editar:hover, .btn-card-reservar:hover {
    border-color: #00b0db;
}


.btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00b0db;
    border-color: #00b0db;
}
.modal_info {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 50px;
}
.modal_info .px-4{
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.bg-header {
    background-image: url(../images/banner-01.png)!important;
    border-bottom: none;
    height: 172px;
    padding: 60px 70px 70px 44px;
}

nav#nav {
    background-color: #E6E7E8;
    border-bottom: none !important;
}

.fadein img {
  max-width: 130px;
  height: auto;
}

.menu_user_name {
    border-left: 2px solid #00b0db;
}
.fadein {
  background-color: rgba(255, 255, 255, 0.98) !important;
}
.section_icons {
    background-image: none!important;
    background-color: white !important;
}
.card {
    background-color: #f3f3f3;
}
.mi_perfil_data {
    border-bottom: 1px solid #00b0db;
}
.col-activity_line {
    border-left: 3px solid #00b0db;
}



.logo_enfoque_detalle {
    height: 28px;
    margin-right: 10px;
}
.activity_today_date {
    color: #888;
}
.btn_modal_cancelar, .btn_modal_cancelar:hover {
    color: white;
}
.activity_title_row {
    margin-left: 16px;
}
.modal_profile input:focus {
    border-bottom: 1px solid #00b0db;
}

.material input {
    background-color: #f9f9f9;
    border-color: #00b0db;
}

.btn-card-cancelar {
    width: 100%;
    background-color: #520000 !important;
    border-color: #520000 !important;
}
.table_historial {
      box-shadow: 0 .75rem 1rem rgba(0,0,0,.175)!important;
}

.modal_profile {
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 4px;
}

.modal_profile p {
    font-size: 17px;
    color: #002028;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 16px !important;    
}
.modal_profile input {
    font-size: 15px;
}
.page-fronthorarios .card_grid {
    background-color: #002028;
    padding-bottom: 2px;
    padding-top: 10px!important;
    box-shadow: 1px 2px 4px #989898;
}
.page-fronthorarios h1 {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: .25em;
}
.page-fronthorarios h5 {
    color: #ffffff;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: .25em;
}
.page-fronthorarios h2 {
    font-size: 15px;
    color: #bbbbbb;
    font-weight: 400;
    margin-bottom: .25em;    
}

.page-fronthorarios h4 {
    font-size: 12px;
    font-weight: 500;
    color: white;
    margin-bottom: .5em;    
}
.developed_singin, .developed_singup {
    color: #002028;
    position: absolute;
    bottom: 4px;
    right: 20px;
    opacity: .6;
}
.developed_singin p, .developed_singup p, .developed_singin p b, .developed_singup p b {
    color: #002028;
}
.row_horarios .activity_btn_back {
    left: unset;
    position: relative;
    margin-top: 10px;
}
.row_horarios {
    justify-content: center;
}
.row_fronthorarios .activity_btn_back {
    float: left;
    position: relative;
}
.row_fronthorarios .form-group.input-group-lg.mb-10.dropdown_icon_global {
    float: unset;

}
.row_fronthorarios {
    justify-content: center;
}













/*
////////////////////////////////////////////////  SINERGIA LIFE ////////////////////////////////////////////////////////////////             
*/

.logo-signin {
    width: 250px;
}
.navbar-brand img {
    max-height: 54px;
    width: auto!important;
    margin-left: 40px;
}
nav#nav {
    background-color: white;
}

.bg-header {
    background-image: url(../images/banner_sinergia_02.jpg)!important;
    border-bottom: none;
    height: 200px;
    padding: 74px 70px 70px 44px;
}
.logo_enfoque_detalle {
    display: none;
}
.dropdown_icon_global:before {
    color: #002028;
}



.home .footer_section_icons, .horarios .footer_section_icons, .fronthorarios .footer_section_icons {
  margin-left: -48px;
}

.reglas .col_welcome_left {
    padding-top: 7%;
}


.reglas a:hover {
  opacity: .7;
}


.modal_info button {
    background-color: transparent;
    border: none;
    padding-left: 1px;
}
span.spacer {
    height: 16px;
    display: block;
}
.row_fronthorarios .activity_btn_back, .horarios .activity_btn_back{
    float: left;
    position: absolute;
    margin-top: -10px;
}
.row_fronthorarios, .row_horarios {
    margin-bottom: 34px;
}



.separator {
    height: 24px;
    float: left;
}




.btn_modal_cancelar.btn-card-reservar {
    background-color: white !important;
    color: #00b0db;
}
.btn-card-reservar .fa-check-circle {
    color: white;
}
.misreservas .dropdown_icon{
  float: unset;
}




i.fas.fa-info, i.fas.fa-bell, i.fas.fa-users {
    position: relative;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    padding-left: 12px;
    padding-top: 8px;
    float: right;
    background: whitesmoke;
    transition: 0.2s;
    margin-right: 4px;
}

i.fas.fa-bell {
    padding-left: 9px;
}

i.fas.fa-info:hover, i.fas.fa-bell:hover, i.fas.fa-users:hover {
    color: white;
    background: #00b0db;
}

.info_card a{
    transition: none;
}

.info_card a:focus {
    color: #002028 !important;
}

.activity_time {
    color: #474747;
    font-weight: 400;
}
.col-showinfo {
    margin: 0 auto;
  
    .btn_modal_cancelar{
      margin: 0!important;
  }

}

.modal-showinfo {

  h5 {
    color: #002028;
    margin-top: 12px;
  }

  .modal-header {
      background-color: white;
      border-bottom: none;
  }
  p {
      font-size: 14px;
      color: #002028;
      font-weight: 300;
      margin-top: 0px!important;
      margin-bottom: 10px;
  }
}
.btn_close_modal_showinfo {
    float: right;
    border: none;
    background-color: transparent;
    font-size: 20px;
    margin-top: 0px;
    margin-right: 0px;

    &:hover {
          opacity: .7;
      }
}

.fa-info-circle {
  color:  #00b0db;
}

.modal_waitinglist_p {
    font-size: 16px;
    padding: 0px 27px;
    padding-top: 24px;
}

.modal_waitinglist_row {
  margin-top: 24px;
  border-top: 1px solid #dee2e6;
}

.waitinglist .dropdown_icon, .historial .dropdown_icon{
  float: unset;
  margin:0 auto;
}
.waitinglist .row, .historial .row{
  margin-left: 0 !important;
  margin-right: 0 !important;  
}

.waitinglist .activity_btn_back, .historial .activity_btn_back{
  left: unset;  
}


.activity_today_date {
    font-weight: 400 !important;
}

.fa-trash-alt {
    color: #a50000;
    font-size: 16px;

    &:hover{
      color: rgba(165, 0, 0, .7);
    }
}

.waitinglist ul.menu-actions {
    list-style: none;
    margin-bottom: 0px;
}




.password_up {
  display: none;
}




/*  ///////////////////////////////////// TOOLTIP ///////////////////////////////////////// */
.action-a {
position: relative;
-webkit-transform: translateZ(0); /* webkit flicker fix */
-webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.action-a .tooltip_css {
background: black;
bottom: 100%;
color: #fff;
display: block;
left: -55px;
min-width: 120px;
margin-bottom: 15px;
opacity: 0;
padding: 6px;
pointer-events: none;
position: absolute;
width: auto;
font-size: 12px;
font-family: Arial, sans-serif !important;
text-align: center;
font-weight: 500;
border-radius: 4px;
line-height: 1.3em;
-webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
   -o-transform: translateY(10px);
    transform: translateY(10px);
-webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
   -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
   -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}


.section_reservas .action-a .tooltip_css {
left: -48px;
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.action-a .tooltip_css:before {
bottom: -20px;
content: " ";
display: block;
height: 20px;
left: 0;
position: absolute;
width: 100%;
}

/* CSS Triangles - see Trevor's post */
.action-a .tooltip_css:after {
border-left: solid transparent 10px;
border-right: solid transparent 10px;
border-top: solid black 10px;
bottom: -6px;
content: " ";
height: 0;
left: 50%;
margin-left: -8px;
position: absolute;
width: 0;
}

.action-a:hover .tooltip_css {
opacity: 1;
pointer-events: auto;
-webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
   -o-transform: translateY(0px);
    transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .action-a .tooltip_css {
display: none;
}

.lte8 .action-a:hover .tooltip_css {
display: block;
}
/*  ////////////////////////////////////// END TOOLTIP //////////////////////////////////////// */




.footer_section_icons{
    margin-left: 0;
}
 .home  .footer_section_icons, .horarios footer{
    margin-left: -48px;
}

.isotipo{
    display: none;
}

.activity_gender {
  display: none;
}






input:-webkit-autofill ~ label {
    display: none;
}

input.ng-dirty.ng-touched.ng-invalid.ng-not-empty.ng-invalid-email.ng-valid-required ~ label {
  display: none !important;
}
.material input {
    padding-right: 14px;
}

.input_password {
  padding-right: 24px;
}



input[type=date]::-webkit-datetime-edit-text, input[type=date]::-webkit-datetime-edit-month-field, input[type=date]::-webkit-datetime-edit-day-field, input[type=date]::-webkit-datetime-edit-year-field {
    color: #f9f9f9;
  }

input[type=date]:focus::-webkit-datetime-edit-text, input[type=date]:focus::-webkit-datetime-edit-month-field, input[type=date]:focus::-webkit-datetime-edit-day-field, input[type=date]:focus::-webkit-datetime-edit-year-field{
    color: black;
}

input[type=date]::-webkit-autofill ~ label {
    display: none;
}
input[type=date].ng-touched ~ label {
    display: none;
}
input[type=date].ng-touched::-webkit-datetime-edit-text, input[type=date].ng-touched::-webkit-datetime-edit-month-field, input[type=date].ng-touched::-webkit-datetime-edit-day-field, input[type=date].ng-touched::-webkit-datetime-edit-year-field {
    color: black;
}
input.ng-invalid-date {
    margin-bottom: 24px;
}



i.fas.fa-users {
    padding-left: 6px;
}
.misreservas .action-a .tooltip_css {
    left: -48px;
}

.historial .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    text-align: left;
    text-align: start;
    margin-top: 12px;
    font-size: 13px;
    font-family: Arial;
    margin-top: 26px !important;
    margin-left: 54px !important;
    background-color: black;
        border-radius: 4px;
}

.historial .tooltip .arrow {
    top: 8px!important;
    left: 109px;
}

.historial .bs-tooltip-left {
    padding: 0 .6rem;
}
.historial  .action-a:hover, .historial  .action-a:focus {
  color: unset;
}
.historial  .action-a span{
  display: none;
}
.modal_guests ul {
    list-style-type: none;
    padding-right: 30px;
    padding-left: 20px;
}
.modal_guests p {
    border-bottom: 1px solid #00b0db;
        font-size: 16px;
}
.confirmar_reservas ul {
    list-style-type: none;
    display: contents;
}

.confirmar_reservas li {
    margin-right: 12px;
    width: 128px;
}
.activity_guests {
    margin-top: 0 !important;
    margin-left: -14px !important;
    font-size: 15px !important;
}
.row_invitados input:focus {
    border-bottom: 2px solid #00b0db;
}
.modal-showinfo i.fas.fa-users {
    float: left;
    background-color: #00b0db;
    color: white;
    padding: 0;
    padding-top: 6px;
    font-size: 14px;
    margin-right: 10px;
    border-radius: 12px;
    width: 25px;
    height: 25px;
}




input:-webkit-autofill ~ label {
    display: none;
}

input.ng-dirty.ng-touched.ng-invalid.ng-not-empty.ng-invalid-email.ng-valid-required ~ label {
  display: none !important;
}
.material input {
    padding-right: 14px;
}

.input_password {
  padding-right: 24px;
}




.horarios .row_horarios {
    justify-content: left;
    padding-left: 80px;
}
.row_fronthorarios {
    justify-content: left;
}







/* ////////////////////////////////// FLEX ///////////////////////////////// */

.bg-header {
    background-image: url(../images/banner-01.png)!important;
}
.container_group_icons .col-lg-3 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.container_icons .image_icons {
    width: 146px;
}









.btn_adv_search {
    color: white;
    border: 2px solid #00b0db;
    padding: 6px 20px;
    border-radius: 18px;
    font-size: 16px;
    margin-bottom: 36px;
    background-color: #00b0db;
}
.btn_adv_search:hover {
    opacity: .75;
        color: #012835;
}


.advsearch_col .list-inline {
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
}
.advsearch_col input[type=checkbox] {
    margin-right: 4px;
    height: 16px;
    width: 16px;
}

.advsearch_container {
    height: 100vh;
    display: contents;
    background-image: none !important;
    max-width: 1200px;
}
.advsearch_row {
    height: auto;
    min-height: 100vh;
}
.advsearch_container .activity_day {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 4px !important;
    margin-top: 4px !important;
        color: #00b0db;
}
.advsearch_container .activity_time {
    margin-top: 2px;
    font-size: 16px;
}
.advsearch_container .col_activity_info {
    padding-top: 8px;
}

.advsearch_container .activity_type {
    font-size: 15px;
}
.advsearch_container .activity_place {
    font-size: 15px;
}

.rzslider .rz-pointer {
    background-color: #00b0db !important;
}
.rzslider .rz-selection {
    background: #00b0db !important;
}
.rzslider .rz-pointer.rz-active:after {
    background-color: #fff !important;
}
.card_shadow {
  border: none;
    box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23)!important;
        height: 100% !important;
        background-color: whitesmoke;
    border-radius: 10px !important;
}
span.rz-bubble.rz-model-value, span.rz-bubble.rz-model-high {
    font-size: 16px;
    padding-left: 2px;
    font-weight: bold;
}





/* /////////////////// ALERT BOX ////////////////////// */

@import url("https://fonts.googleapis.com/css?family=Lobster");

@import url("https://squalus-public-production.s3.us-east-2.amazonaws.com/agenda/font-awesome.min.css");
 
 .alert .inner {display: block; padding: 16px 24px; margin: 6px; border-radius: 3px; border: 1px solid #fff3cd; background-color: #fff3cd; margin-bottom: 32px; } .alert p {color: #856404; font-size: 15px; } .alert h3 {color: #856404; } .alert .close {float: right; margin: 12px 16px 0px 0px; cursor: pointer; } .alert .inner,.alert .close {color: rgb(88,88,88); } .alert input {display: none; } .alert input:checked ~ * {animation-name: dismiss,hide; animation-duration: 300ms; animation-iteration-count: 1; animation-timing-function: ease; animation-fill-mode: forwards; animation-delay: 0s,100ms; } .alert.warning .inner,.alert.warning .close {color: #856404 } @keyframes dismiss {0% {opacity: 1; } 90%, 100% {opacity: 0; font-size: 0.1px; transform: scale(0); } } @keyframes hide {100% {height: 0px; width: 0px; overflow: hidden; margin: 0px; padding: 0px; border: 0px; } } .alert.warning {border: none!important; border-radius: 0!important; padding-top: 0; padding-bottom: 0; margin-bottom: 0; }
 /* /////////////////// ALERT BOX ////////////////////// */


.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal_info p {
    margin-bottom: 6px;
}


.btn_reservar:focus {
    color: #00b0db;
}
.btn_reservar .fa-envelope {
    font-size: 20px;
    margin-right: 10px;
    margin-left: 6px;
}
.numero_mensajes {
    background-color: red;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    font-size: 14px !important;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 1px;
    position: absolute;
    margin-left: 21px;
    margin-top: -10px;
    display: inline-table;
    text-align: center;
        color: white !important;
}
.mensaje_titulo {
    font-size: 18px;
}

.message .info_card {
    float: right;
}
.message .btn-card-reservar {
    margin-top: 10px;
    font-size: 10px;
    padding: 4px 15px;
    width: 60%;
    float: right;
}
.mensaje_titulo_leido {
    color: #666;
}
.fa-envelope-open-text {
    margin-right: 4px;
}
.modal_message p {
    font-size: 15px;
}
.mensaje_dia_hora {
    font-size: 12px;
    color: #676767;
    font-weight: 300;
}
.message .card {
    padding-bottom: 0px !important;
    padding-top: 24px !important;
}
.message .info_card {
    float: right;
    position: absolute;
    right: 12px;
    top: 8px;
    display: block;
    z-index: 999;
}

.menu_user_name span {
    font-size: 11px;
    vertical-align: text-bottom;
    color: #00b0db;
}
.dropdown-toggle::after {
  display: none;
}
.modal-dialog .activity_responsible{
    font-size: 16px;
    margin-bottom: 2px;
}
.modal-dialog .activity_type{
    display: none;
}

.btn_qr {
  display: none;
}



/* //////////////// PROFILE //////////////// */
.profile_image_container {
    width: 172px;
    height: 172px;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    background-color: white;
}
.profile_image_container_hover {
    background-color: rgba(0, 0, 0, 0.68);
    left: 0;
    right: 0;
    top: 28px;
    position: absolute;
    opacity: 0;
}
.profile_image_container_hover:hover {
    opacity: 1;
}
.profile_image_container_hover i.fa.fa-upload {
    color: white;
    font-size: 30px;
    margin-top: 40%;
    margin-left: 40%;
}
.btn_profile_edit_load i.fa.fa-upload {
    color: #fff;
    font-size: 16px;
    vertical-align: bottom;
    margin-top: 24px;
}
.btn-profile {
    border-left: none;
    padding: 5px 10px;
    font-size: 13px;
    background-color: #00b0db;
    color: white;
    border-radius: 4px;
}
.btn-profile:focus {
  color:white;   
}
.btn-profile:hover {
  color:white !important; 
  opacity: .75;
  background-color: #00b0db;

}
.mi_perfil {
    border-radius: 10px;
    margin-top: 32px;
    padding-bottom: 20px !important;
}
.btn-profile:first-child {
    border: none;
}
.btn_profile_edit_load {
    padding: 4px 8px;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0.68);;
    position: absolute;
    left: 0;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: inline-table;
    object-fit: fill;
    opacity: 0;
}
.btn_profile_edit_load:hover {
    background-color: rgba(0, 0, 0, 0.68);;
    color: white;
    opacity: 1;
}
.btn_profile_edit_view {
    border-left: none;
    padding: 0 8px;
    font-size: 12px;
    color: #00b0db;
    border-radius: 4px;
    display: inline-table;
    margin-top: 10px;
    line-height: 1.25em;
}
.btn_profile_edit_view:hover {
    color: #00b0db;
    text-decoration: underline;
}
.container_profile_edit_files {
   border-bottom: 1px solid #00b0db;  
}
span.profile_edit_title {
    font-size: 12px;
    color: #666;
    width: 100%;
    display: inline-flex;
}
.modal-dialog.modal-lg {
    margin-top: 4% !important;
}
header .profile_image_container {
    width: 34px;
    height: 34px;
    display: inline-block;
    border-radius: 50px;
    margin-top: -7px;
    position: absolute;
    left: -8px;
}
/* ////////////////  PROFILE ENDS  //////////////// */







.menu_user_name {
    border-left: none;
}
.btn_reservar .fa-envelope {
    margin-right: 26px;
    margin-left: 8px;
}
.menu_user_name span {
    padding-left: 3px;
}







/* ////////////////  BIRTHDAY  //////////////// */


  .container_birthday {
        height: 100vh;
        overflow: hidden;
        position: fixed;
        top: 0;
        z-index: 9999;
    }
  .container_birthday .balloon {
    display: inline-block;
    width: 120px;
    height: 145px;
    background: #85d6ff;
    border-radius: 80%;
    position: relative;
    -webkit-box-shadow: inset -10px -10px 0 rgba(0, 0, 0, 0.07);
    box-shadow: inset -10px -10px 0 rgba(0, 0, 0, 0.07);
    margin: 20px 30px;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    z-index: 10;
    -webkit-animation: balloons 4s ease-in-out infinite normal;
    animation: balloons 4s ease-in-out infinite normal;
    -webkit-transform-origin: bottom center;
    -ms-transform-origin: bottom center;
    transform-origin: bottom center; }
    .container_birthday .balloon:before {
      content: "▲";
      font-size: 20px;
      color: #85d6ff;
      display: block;
      text-align: center;
      width: 100%;
      position: absolute;
      bottom: -12px;
      z-index: -100; }
    .container_birthday .balloon:after {
        display: inline-block;
        top: 153px;
        position: absolute;
        height: 250px;
        width: 1px;
        margin: 0 auto;
        content: "";
        background: rgba(0,0,0,.2);
        margin: 0 auto;
        left: 0;
        right: 0;
    }
    .container_birthday .balloon:nth-child(2) {
      background: #deb3ff;
      -webkit-animation-duration: 3.5s;
      animation-duration: 3.5s; }
    .container_birthday .balloon:nth-child(2):before {
      color: #d8b8ff; }
    .container_birthday .balloon:nth-child(3) {
      background: #4dff52;
      -webkit-animation-duration: 3s;
      animation-duration: 3s; }
    .container_birthday .balloon:nth-child(3):before {
      color: #4dff52; }
    .container_birthday .balloon:nth-child(4) {
      background: #42f2ff;
      -webkit-animation-duration: 4.5s;
      animation-duration: 4.5s; }
    .container_birthday .balloon:nth-child(4):before {
      color: #42f2ff; }
    .container_birthday .balloon:nth-child(5) {
      background: #ce8355;
      -webkit-animation-duration: 4s;
      animation-duration: 4s; }
    .container_birthday .balloon:nth-child(5):before {
      color: #a96942; }
    .container_birthday .balloon:nth-child(6) {
      background: #ffd6e7;
      -webkit-animation-duration: 5.5s;
      animation-duration: 5.5s; }
    .container_birthday .balloon:nth-child(6):before {
      color: #ffd6e7; }
    .container_birthday .balloon:nth-child(7) {
      background: #ffa352;
      -webkit-animation-duration: 2.9s;
      animation-duration: 2.9s; }
    .container_birthday .balloon:nth-child(7):before {
      color: #ffa352; }
    .container_birthday .balloon:nth-child(8) {
      background: #ffff47;
      -webkit-animation-duration: 6s;
      animation-duration: 6s; }
    .container_birthday .balloon:nth-child(8):before {
      color: #ffff47; }

@-webkit-keyframes balloons {
  0% {
    top: 100%;
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg); }
  100% {
    top: -100%;
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg); } }

@keyframes balloons {
  0% {
    top: 100%;
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg); }
  100% {
    top: -100%;
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg); } }




.fadeOut {
  animation: 9s fadeOut;
  animation-fill-mode: forwards;
  visibility: hidden;
}
 
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
 
  90% {
    visibility: visible;
    opacity: 1;
  }  
  
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
/* ////////////////  BIRTHDAY ENDS  //////////////// */


.fronthorarios_row {
    border-top: 1px solid #bfbfbf;
    padding-top: 14px;  
    padding-bottom: 6px;
}
.fronthorarios_time {
    color: #00b0db !important;
    font-weight: bold !important;
}
.fronthorarios_day {
    font-size: 20px !important;
}



.btn_profile_edit_load {
    padding: 1px 9px;
    font-size: 12px;
    background-color: unset;
    position: relative;
    left: unset;
    width: unset;
    height: unset;
    display: block;
    object-fit: unset;
    opacity: 1;
    background-color: #ff5151;
    color: #fff;
    border-radius: 4px;
}
.btn_profile_edit_load:hover {
    background-color: #ff5151;
    color: #fff;
    opacity: .8;
}
.profile_edit_title {
    font-size: 14px!important;
    color: #666;
    width: 100%;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-weight: 500;
    margin-bottom: 6px;
}
.btn_profile_edit_load i.fa.fa-upload, .btn_profile_edit_view .fa-eye {
    color: #fff;
    font-size: 12px;
    vertical-align: unset;
    margin-top: 0;
    margin-left: 7px;
}
.btn_profile_edit_view {
    padding: 5px 10px!important;
    font-size: 12px;
    position: relative;
    left: unset;
    width: unset;
    height: unset;
    display: block;
    object-fit: unset;
    opacity: 1;
    background-color: #4ead69;
    color: #fff;
    border-radius: 4px;
}
.btn_profile_edit_view:hover {
    padding: 5px 10px!important;
    font-size: 12px;
    position: relative;
    left: unset;
    width: unset;
    height: unset;
    display: block;
    object-fit: unset;
    opacity: 1;
    background-color: #4ead69;
    color: #fff;
    border-radius: 4px;
    opacity: .8;
    text-decoration: none;
}
.btn_profile_edit_pre {
    background-color: #f5f5f5;
    font-size: 12px;
    color: #252525;
    padding: 0px 10px;
    display: inline-block;
}
.fadeOut {
  animation: 12s fadeOut;
  animation-fill-mode: forwards;
  visibility: hidden;
}
 
@keyframes fadeOut {
  0% {
    visibility: hidden;
    opacity: 0;    
  }

  33% {
    visibility: visible;
    opacity: 1;
  } 
 
  90% {
    visibility: visible;
    opacity: 1;
  }  
  
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    visibility: hidden;
    opacity: 0;    
  }

  33% {
    visibility: visible;
    opacity: 1;
  } 
 
  90% {
    visibility: visible;
    opacity: 1;
  }  
  
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
  .alert_container  p {
      margin-bottom: 5px;
  }


header .profile_image_container {
    background-size: 48px;
}


.modal.show .modal-dialog {
    transform: none;
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 45%;
    margin: 0 !important;
    max-width: 610px!important;
    width: 100%;
    transition:0s;
}
.modal.show .modal-dialog.modal-md{
    top: 50%;
    max-width: 720px!important;
    width: 100%;
    transition: 0s;
}
.modal.show .modal-dialog.modal-lg{
    top: 46%;
    max-width: 800px!important;
    width: 100%;
    transition:0s;
}
.modal .alert.alert-warning {
    width: 90%;
    margin: 0 auto;
    padding: 12px 18px;
    margin-top: 12px;
}
.modal-open .fade {
  transition: opacity 0s linear;
}


/* //////////   ROUTINE  ////////// */
.row.row_horarios.col-4 {
    margin-bottom: 0;
}

.card_routine {
  margin: 0 auto;
  padding: 0 !important;
  background-color: white;
  margin-top: 2px !important;

  .body_routine {

    margin-top: 12px;

    h5 {
      font-family: Roboto,Arial,sans-serif!important;
      color: #00b0db;
      font-size: 44px;
      text-align: center;
      padding: 28px 0;
      padding-bottom: 14px;
   }

  .body_container_content {
      padding: 14px 24px;
      background-color: #f2f2f2f2;
      border-bottom: 1px solid #d8d4d4;
      padding-top: 34px;
      width: 95%;
      margin: 0 auto;

      ul {
          margin-bottom: 0;
          padding-bottom: 20px;
          
          padding-left: 10px;
          margin-left: 18px;
          padding-top: 10px;       


        li {
          list-style: none;

          h3 {
              font-size: 22px;
              text-transform: uppercase;
              font-weight: bold;
              text-decoration: none;
          } 
          h6 {
              
              padding-left: 12px;
              font-size: 21px;
              font-weight: 400;
              margin-top: 12px;
          } 
          span {
              margin-left: 16px;
              font-size: 18px;
              display: block;
              color: #888;
              border-left: 5px solid #00b0db;
              padding-left: 12px;
              padding-bottom: 4px;
              padding-top: 4px;

              b {
                color: #4c4c4c;
                text-transform: uppercase;
              }
          } 
          path {
              padding: 0 6px;
              color: #012835;
              font-weight: 900;
          }                                 
        }
      }
  }

  .body_title_routine {

      h4 {
          font-size: 30px;
          color: #012835;
          text-transform: uppercase;
          font-weight: 900;
          padding-left: 10px;
          margin-bottom: 16px;
          text-align: center; 
      }
    }

  }


  .header_routine {
      background-image: url(../images/banner-01.png)!important;
      border-bottom: none;
      padding: 30px;
      padding-top: 32px;
      padding-bottom: 32px;
      background-size: cover;
      background-position: center;
      border-bottom: 6px solid #00b0db;
      background-color: #e0e0e0;
      background-blend-mode: multiply;

    h1 {
      font-family: Roboto,Arial,sans-serif!important;
      font-size: 54px;
      padding-top: 16px;
      color: #fff;
      text-align: left;
      line-height: .9em;
    }

  }

}
.title_activity_routine {
    margin: 0 auto;
    display: block;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
}
.routine .footer_section_icons {
    margin-left: -48px;
}
.btn_ver_horarios .fa-dumbbell {
    font-size: 18px;
    margin-right: 10px;
}
.btn_routine_filter {
    margin: 0!important;
    padding: 6px 4px;
    border-color: #00b0db;
    color: #00b0db;
}
.btn_routine_filter:hover {
    border-color: #00b0db;
    color: #00b0db;
}
 
 .title_type_routine {
    margin: 16px auto 10px;
    display: block;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    color: #4c4c4c;
}
.btn-select {
    background-color: #00b0db !important;
    color:white !important;
}
/* //////////   ROUTINE  ENDS ////////// */



.btn_filters_message {
    max-width: 100px;
    border-radius: 30px;
    padding: 5px 12px;
    margin-left: 10px;
    margin-right: 10px;
    border-color: #002a47;
    background-color: #fff;
    color: #002a47;
}
.btn_filters_message:focus, .btn_filters_message:active {
    border-color: #002a47 !important;
    background-color: #fff !important;
    color: #002a47 !important;
}
.btn_filters_message:hover {
    border-color: #002a47;
    background-color: #fff;
    color: #002a47;
    opacity: .65;
}
.btn_filters_message_selected {
    max-width: 100px;
    border-radius: 30px;
    padding: 5px 12px;
    margin-left: 10px;
    margin-right: 10px;    
    border-color: #fff;
    background-color: #002a47;
    color: #fff;  
}
.btn_filters_message_selected:hover  {
    border-color: #fff;
    background-color: #002a47;
    color: #fff;  
    opacity: 1;
}

.btn_datepicker {
    border-radius: 20px;
    border-color: #112b54;  
}
.btn_datepicker_search {
    margin-top: 30px;
    display: block;
}
.btn_datepicker span {
    color: #112b54 !important;  
}
input[type=date]::-webkit-datetime-edit-day-field, input[type=date]::-webkit-datetime-edit-month-field, input[type=date]::-webkit-datetime-edit-text, input[type=date]::-webkit-datetime-edit-year-field {
    color: #112b54 !important;  
}
.table_historial {
    margin-top: 16px;
}

  .horarios_mobile {
      display: none;
  }






i.far.fa-money-check-alt {
    font-family: "Font Awesome 6 Pro";
    font-size: 18px;
    margin-right: 4px;
    color: #00b0db;
}



.card .col-7 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.new_activity_time {
    width: 60%;
}
.new_btn_reservar {
    width: 40%;
}
.activity_time {
    margin: 0;
}
.activity_price {
    display: block!important;
    margin-top: 6px;
    font-size: 20px!important;
    text-align: center;
}



.reservaactividadcustom select {
    border-radius: 4px;
    padding: 6px 10px !important;
}
.reservaactividadcustom button:hover {
  opacity: .9;
  border: 2px solid #afafaf;
  background-color: #f7f7f7;  
}
.reservaactividadcustom button span{
  font-weight: 700;
}
.reservaactividadcustom .dropdown_icon_global:before {
    top: 4px;
}
.reservado button{
    background-color: #ff5454 !important;
    color: white;
    border: 2px solid #ff5454;    
}
.reservado button:hover {
    opacity: 1;
    border: 2px solid #ff5454;     
}
.reservaactividadcustom footer {
  left: 0%;
}
.reservaactividadcustom button {
    border: 2px solid #d1d1d1;
    width: 100%;
    padding: 8px 14px;
    font-size: 20px;
    background-color: #fff;
    border-radius: 6px;
    font-size: 16px;
}
.reservaactividadcustom i.fas.fa-exclamation-circle {
    margin-top: 6px;
}


























/*
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/
































@media only screen and (max-width: 1440px) {

  .profile_image_container {
      width: 148px;
      height: 148px;
  } 

}















@media only screen and (max-width: 1200px) {

    .title_icons {
        font-size: 16px;
        line-height: 1.3em;
    }
    .container_icons {
        float: left;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 34px;
    }    
    .container_welcome h1 {
        font-size: 30px;
    }
    .container_welcome p {
        font-size: 16px;
        line-height: 1.3em;
    }
     .signin-container {
        margin-top: 54px;
    }  
    .logo-signin {
        width: 250px;
        height: auto;
        display: block;
        margin: 50px auto 0;
    }   
    .image_icons {
        width: 84px;
    }    
    .modal-dialog.modal-lg {
        margin-top: 7%;
    }  
    .modal_info p {
        font-size: 13px;
    }     
    .container_icons {
        min-height: 192px;
    }   
    .page-fronthorarios {
        padding-left: 1.25em!important;
        padding-right: 1.25em!important;
    }  
    .page-fronthorarios h3 {
        font-size: 23px;
    }     
    .page-fronthorarios .footer_section_icons {
        margin-left: 0px;
    }
    .col_activity_time {
        padding-left: 10px;
        padding-right: 10px;
    }

    .message .btn-card-reservar {
        width: 70%;
    }
}




@media only screen and (max-width: 1024px) {

    .title_icons {
        font-size: 16px;
        line-height: 1.3em;
    }
    .container_icons {
        float: left;
        padding-left: 26px;
        padding-right: 26px;
        padding-bottom: 34px;
    }    

    .page-fronthorarios h3 {
        font-size: 22px;
    }
    .page-frontcategorias .container_group_icons {
        padding-left: 100px;
        padding-right: 100px;
    }  
      .signin-container {
        margin-top: 44px;
    }  
    .logo-signin {
        width: 250px;
        margin: 40px auto 0;
    } 
    .image_icons {
        width: 74px;
    }
    .modal-dialog.modal-lg {
        margin-top: 5%;
    }     
    .container_icons {
        min-height: 178px;
    }   
    .page-fronthorarios {
        padding-left: 1em!important;
        padding-right: 1em!important;
    }             

    .page-fronthorarios .footer_section_icons {
        margin-left: 0px;
    }
    .reglas .col_welcome_left {
        padding-top: 7%;
        max-width: 70%;
    }    
}








@media only screen and (max-width: 992px) {

    .container {
      max-width: 900px;
    }

    .container_icons {
        float: left;
        padding-bottom: 24px;
    }
    .title_icons {
        font-size: 15px;
        line-height: 1.3em;
    }

    .page-fronthorarios h3 {
        font-size: 20px;
    }
   .page-fronthorarios {
    padding-left: .75em !important;
    padding-right: .75em !important;  
   }
    .modal-dialog.modal-lg {
        max-width: 800px;
    }  
    .container_icons {
        min-height: 140px;
    }    
    .page-fronthorarios .footer_section_icons {
        margin-left: 0px;
    }    
    .container_icons .image_icons {
        width: 136px;
    }

}



@media only screen and (max-width: 880px) {

    .container_icons {
        padding-bottom: 20px;
    }
    .title_icons {
        font-size: 14px;
        line-height: 1.3em;
    }
    .page-fronthorarios .col-sm-2 {
        padding-left: 5px;
        padding-right: 5px;
    }
    .page-fronthorarios h3 {
        font-size: 19px;
    }
 
    .page-fronthorarios h5 {
        font-size: 16px;
    }   
    .page-frontcategorias .container_group_icons {
        padding-left: 90px;
        padding-right: 90px;
    }    
     .signin-container {
        margin-top: 36px;
    }     
    .container_icons .image_icons {
        width: 126px;
    }

}






@media only screen and (max-width: 800px) {

    .container_signup, .container_welcome {
        text-align: center;
        margin-top: 13%;
    }
    .signin-container {
        padding-left: 4rem!important;
        padding-right: 4rem!important;
        margin-top: 32%;
    }    
    .signup-container {
        padding-left: 34px !important;
        padding-right: 34px !important;
    }
    .btn_recovery_pw {
        margin-top: 10px !important;
    }    

    .container_group_icons {
        padding-left: 64px;
        padding-right: 64px;
    }
   .col_welcome_left {
        -ms-flex: 0 0 70% !important;
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }  
    .modal-dialog .modal_info p {
        line-height: 1.35em;
        font-size: 13px;
    }    
    .container_maintenance {
        text-align: center;
        margin-top: 7%;
    }   
   .page-fronthorarios {
    padding-left: .5em !important;
    padding-right: .5em !important;  
   }      
    .page-fronthorarios .footer_section_icons {
        margin-left: 0px;
    }   
    .page-fronthorarios h1 {
        font-size: 15px;
    }
    .page-fronthorarios h5 {
        font-size: 13px;
    }
    .page-fronthorarios h2 {
        font-size: 14px;  
    }
    .page-fronthorarios h4 {
        font-size: 11px;  
    }    
    .reglas .col_welcome_left {
        padding-top: 7%;
    }    
    .reglas .container_welcome {
        text-align: center;
        margin-top: 3%;
    }         
}






@media only screen and (max-width: 768px) {
ul.nav.navbar-nav.navbar-right {
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    width: 100%;
}
img.logo{
  max-width: 180px;
}
#menu-toggle {
  display: none;
}
#menu {
  width: 100%;
  overflow: hidden;
  max-height: 0;
  padding: 0;
  margin: 0 auto;
  -webkit-transition: all 0.3s ease;
}
#menu-toggle:checked + #menu {
  max-height: 170px;
}
#nav.navbar-default .navbar-nav >li {
    float: right;
    width: 100%;
    text-align: center;
}
label.menu-m{
  /* position: absolute; */
    right: 20px;
    width: 40px;
    height: 40px;
    margin-top: 20px;
    /* background: red; */

}
    label.menu-m .icon-bar {
        background-color: #888;
        height: 2px;
        width: 23px;
        /* position: absolute; */
        position: relative;
        float: left;
        margin-top: 5px;
    }

    .navbar-brand img{
    max-height:81px !important;
    width: auto !important;
    /* float:left; */
    }

    ul.nav.navbar-nav.navbar-right {
        border-top: 0px solid #D8D8D8;
        border-bottom: 0px solid #D8D8D8;
        width: 100%;
    }

    .navbar-header {
      height: 81px;
    }

    img.logo {
        max-width: 180px;
        margin-top: 16px;
        /* padding-left: 10px; */
        margin-left: 10px;
    }

      .fila-nav img {
        width: 100%;
        max-width: 200px;
        padding: 0px 10px;
    }
    .column-left img{
      display: none;
    }



  #nav .navbar-toggle {
      border: none;
  }

  .navbar-brand h4{
    font-size: 13px;
        padding: 0px;
        margin: 0px;
        line-height: 35px;
  }
    .title_icons {
        font-size: 14px;
        line-height: 1.2em;
    }

    .container_icons {
        float: left;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-top: 0;
        min-height: 172px;
    }
    .container_group_icons {
        padding-left: 54px;
        padding-right: 54px;
    }
    .navbar-brand img {
        max-height: 58px!important;
        margin-top: 10px;
        width: auto!important;
    }

    .bg-header {
        height: 140px;
        border-bottom: 3px solid #fff;
        padding: 50px 70px 70px 44px;
        color: #fff;
    }
    h1 {
        font-size: 35px;
    }
    .page-section {
        padding: 20px 0;
    }
    .reservas_dropdown .dropdown-menu {
        left: 0;
        top: 50px;
    }


    .modal-dialog .modal_info p {
        line-height: 1.35em;
        font-size: 13px;
    }

    .material {
        margin: 5px 0 10px;
    }

    .material input {
        padding: 7px 0 7px 18px;
    }
    .forgot-password {

        margin-top: -115px;
    }
    .btn-signin {

        margin-top: 30px;
    }
    .logo-signin {
        width: 250px;
    }

    .col_welcome_right, .col_welcome_left  {
        -ms-flex: 0 0 50%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col_welcome_left {
        padding-top: 10%;
    }
    .tab-container .tab-content-container {
        width: 100%;
    }
    .page-frontcategorias .container_group_icons {
        padding-left: 80px;
        padding-right: 80px;
    }    



    .historial .tooltip {
        display: none;
    }
    .historial  .action-a span{
      display: block;
    }


  .card_shadow {
      margin-left: 15px !important;
  }

  .section_cuponeras  .container_cuponeras {
    width: 80% !important;
  }
  .comprarcuponera .container {
    padding-right: 0;
    padding-left: 0;
  }
  .kanan .info {
      margin-left: 4px !important;
      margin-right: 4px !important;
  }

  .card_routine {
      margin: 0 auto!important;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
  }
  .routine .row.row_horarios.col-4 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: left;
  } 
  .card_routine .header_routine h1 {
      font-size: 64px;
  }   
  .modal-dialog.modal-lg {
      width: 94% !important;
  } 
}





@media only screen and (max-width: 600px) {
    .navbar-brand img {
        max-height: 50px!important;
        margin-top: 10px;
        width: auto!important;
    }
    nav#nav {
        padding-bottom: 2px;
        padding-top: 12px;
    }
    .page-fronthorarios {
        padding-left: 1em!important;
        padding-right: 1em!important;
    }
    .page-fronthorarios h3 {
        font-size: 18px;
    }
    .page-fronthorarios h1 {
        font-size: 15px;
    }
    .page-fronthorarios h5 {
        font-size: 15px;
    }
    .page-fronthorarios h2 {
        font-size: 13px;
    }
    .page-fronthorarios h4 {
        font-size: 12px;
    }
    .modal-dialog .modal_info p {
        line-height: 1.35em;
        font-size: 12px;
    }
    .modal_info .col-sm-4.px-4 {
        padding-left: .35em !important;
        padding-right: .35em !important;
    }     
    .page-fronthorarios .col-sm-2 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }      
    .page-fronthorarios h1, .page-fronthorarios h5 {
        font-size: 22px;
    }
    .page-fronthorarios h2 {
        font-size: 22px;
    }
    .page-fronthorarios h4 {
        font-size: 16px;
    }
    .page-fronthorarios .footer_section_icons {
        margin-left: 0px;
    }
    .reglas .col_welcome_left {
        max-width: 92% !important;
    }    
}






@media only screen and (max-width: 480px) {
  .navbar-brand img{
  max-height:30px;
  }
  .navbar-brand h4{
    font-size: 11px;
    line-height: 30px;
  }
  .modal-lg  .modal_info {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 50px;
  }  
  .modal-dialog.modal-lg {
      top: 50% !important;

        p {
        line-height: 1.35em;
        font-size: 12px;
        margin-bottom: 12px;
    }
  }

    .logo-signin {
        height: auto;
        display: block;
        margin: 30px auto 0;
        width: 250px;
    }
    .container_signup h1, .container_welcome h1 {
        font-size: 25px;
    }
    .container_signup p, .container_welcome p {
        font-size: 14px;
        line-height: 1.2em;
    }
    .col_welcome_right {
        padding-top: 10px;
        padding-left: 0;
        padding-right: 0;
    }
    .tab-container .tab {
        font-size: 12px;
    }   
    .tab-container .tab-toggle.active~.tab-content-container, .tab-container .tab-toggle:checked~.tab-content-container {
        height: auto;
        opacity: 1;
        min-height: auto;
    } 
    .tab-container .tab-content-container {
        padding: 0 14px 0 14px;
    } 
    .tab-container .tab-content-container .tab-content {
        opacity: 0;
        padding: 5px 0;
        min-height: 185px;
        transition: opacity .1s ease-in;
    }
    .accordion>.accordion-toggle:checked~section {
        height: auto;
    }   
    .accordion>section p {
        font-size: 12px;
    }    

    .accordion b {
        font-size: 11px;
        padding: 2px 5px;
        vertical-align: text-top;
    }    
    .container_icons {
        float: left;
        min-height: 162px;
        padding: 0 20px;
        width: 50%;
        padding-bottom: 20px;
    }
    .container_group_icons {
        padding-left: 0;
        padding-right: 0;
    }
    .container, .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
    }    
    .navbar-header {
        height: 81px;
        margin: 0 auto;
    }
    .navbar-brand img {
        max-height: 52px!important;
        margin-top: 12px;
        margin-left: 0;
        width: auto!important;
    }

    .header_right {
        display: inline-flex;
        width: 100%;
        background-color: #002028;
        height: 56px; 
        padding-left: 14px;
    }
    .container_reservar_ya {
        margin-left: 20px;
    }

    nav#nav {
        padding-bottom: 0px;
    } 
    .bg-header {
        height: 100px;
        padding: 30px 10px 10px 24px;
    } 
    .bg-header h1 {
        font-size: 26px;
    }

    .dropdown-menu {
        width: 414px;
        position: absolute;
        right: 0!important;
        box-shadow: 0px 22px 39px rgba(0, 0, 0, 0.5);
        margin-top: 0 !important;
    }
    .dropdown-item {
        font-size: 19px;
        font-weight: 300;
        padding: .55rem 1.5rem;
    }
    .dropdown_icon_global {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        margin-bottom: 9px;
        margin-top: 0;
    }
    .activity_today_date {
        font-size: 16px;
    }
    .activity_card_none {
        margin-top: 8px;
    }
    .card{
      margin-top: 8px;
      margin-bottom: 32;
    }
    .activity_title, .activity_card_none .activity_title {
        font-size: 18px;
    }

    .activity_card_none .activity_title {
        font-size: 18px;
        margin-bottom: 6px;
    }
    .chevron {
        margin-top: 2px;
    }
    .col_activity_info {
        padding-left: 0;
    }
    .col_btn_reservar {
        padding-right: 2px;
        padding-left: 2px;
        text-align: center;
    }

    .activity_occupancy {
        font-size: 13px;
    }     
    .col_activity_time {
        padding-left: 2px;
        padding-right: 2px;
    }
    .activity_time {
        font-size: 16px;
    }
    .col_signup_left {
        top: 40px;
    }
    .signup-container {
        padding-bottom: 0!important;
        padding-top: 56px;
    }
    .material input {
        padding: 5px 0 5px 18px;
    }
    .btn-signin {
        margin-top: 20px;
    }

    .btn-signin {
        margin-top: 28px;
        font-size: 14px;
        padding: 6px 20px;
    }
    .forgot-password {
        margin-top: -103px;
    }

    .modal_info img.image_icons {
        float: left;
        padding-right: 20px;
        margin-bottom: 22px;
    }
    .modal_info img.image_icons {
        float: left;
        padding-right: 20px;
        padding-bottom: 10px;
        margin-bottom: 42px;
    }
    .modal_info .col-sm-4 {
        margin-bottom: 20px;
    }
    .modal-dialog .activity_time {
        font-size: 19px;
    }
    .modal-header {
        background-color: #002028;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .modal-dialog .activity_title_date {
        font-size: 19px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 28px;
    }
    .row_invitados {
        margin: 14px 0 0;
    }
    .row_invitados input {
        width: 100%;
        margin-bottom: 12px;
        border-top: 1px solid gainsboro;
        border-left: 1px solid gainsboro;
        border-right: 1px solid gainsboro;
        border-bottom: 1px solid #c7c7c7;
    }
    .row_invitados p {
        margin-bottom: 4px;
    }
    .signin-container {
        padding-left: 2rem!important;
        padding-right: 2rem!important;
    }
    div.form-group.form-error div {
        color: #e30000;
        position: relative;
        margin-top: -12px;
        margin-left: 2px;
        font-size: 12px;
        text-align: left;
    }
    .material {
        padding-top: 4px;
        margin: 5px 0 10px;
    }   
    .form-group {
        margin-bottom: .5rem;
    }
    .activity_day {
        line-height: 1.1em;
    }
    .historial thead {
        display: none;
    }
    .historial tr {
        display: grid;
        border-bottom: 2px solid #00b0db;
        margin-bottom: 12px;
    }
    .historial tbody th {
        font-size: 16px;
        color: white;
        background-color: #00b0db;
        padding: 6px 10px;
    }
    .historial td {
        height: 34px;
        padding-top: 4px;
    }
    .col-4.col_misreservas_time {
        padding-left: 4px;
        padding-right: 4px;
    }   
    .card_grupo_familiar {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .card_covid p {
        font-size: 13px;
        line-height: 1.4em;
    }    
    .icon_exclamation {
      width: 100px;
    }    
    .accordion>label {
        margin-bottom: 0;
    }

    .footer .container {
        display: block;
        text-align: center;
        -ms-flex-pack: none;
        justify-content: none;
    }    
    .socialmedia {
        margin-bottom: 20px;
    }
    .footer {
        bottom: -150px;
        padding: 24px 0 !important;
        padding-bottom: 6px !important;    
    }
    .activity_title_date {
        text-align: center;
        margin-left: 0 !important;
    }
    .dropdown_icon_right {
        display: table;
        float: none;
        margin: 8px auto;
    }
    .developed_singin, .developed_singup {
        opacity: 1;
    }
    .developed_singup {
        position: relative;
        float: right;
        margin-top: 40px;

        p{
          margin-bottom: 0.5em;
        }
    }

    .mi_perfil_data {
        padding-left: 0 !important;
    }
    .btn-card-reservar {
        padding: 2px 6px;
        margin-top: 18px;
        width: 100%;
    }
    .btn_editar {
        position: absolute;
        left: 320px;
        margin-top: 4px !important;
    }
    .modal_profile p {
        margin-top: 14px !important;
        margin-bottom: 0;
    }
    .modal_profile input {
        font-size: 15px;
    }   
    .fa-user {
        padding-right: 8px;
    }
    .menu_user_name {
        padding-left: 10px;
    }    
    .btn_reservar p {
        color: #fff;
        font-size: 17px;
        line-height: 1.3em;
    }     
  
    .page-maintenance .developed_singup {
        position: absolute;
        bottom: 14px;
    } 
    .container_maintenance {
        margin-top: 24%;
    }  
    .page-fronthorarios h3 {
        font-size: 28px;
    }

    .page-fronthorarios .card_grid {
        padding-bottom: 6px;
        padding-top: 18px!important;
    }    
    .page-frontcategorias .logo-signin, .page-fronthorarios .logo-signin {
        margin-top: 20px;
        margin-bottom: 10px;
        width: 170px;
    }
    .container_signup {
        margin-top: 4%;
    } 
    .page-frontcategorias .container_group_icons {
        padding-left: 0px;
        padding-right: 0px;
    }  
    .modal_info img.image_icons {
        display: none;
    }   
    .modal_info .col-sm-4.px-4:last-child {
        border-right: none;
        margin-top: 0;
        margin-bottom: 0;
    }    
    .modal_info .col-sm-4.px-4 {
        padding-left: 1.5em!important;
        padding-right: 1.5em!important;
    }     
    .page-fronthorarios .col-sm-2 {
        -ms-flex: unset;
        flex: unset;
        max-width: unset;
    }  
    .page-fronthorarios {
        padding-top: 2em !important;
    }  
    .page-fronthorarios .dropdown_icon_global {
        margin-bottom: 20px !important;
    }   
    .btn_menu, .btn_menu:hover {
      color: white;
    }  
    .col_misreservas_btn {
        padding-right: 2px;
    }
    .activity_card {
        padding-left: 10px;
        padding-right: 10px;
    }   
    .historial tbody {
        color: #002028;
    }    
    .modal_profile {
        padding-left: 3em;
        padding-right: 3em;

    }    
    .reglas .col_welcome_left {
        max-width: 94% !important;
    }
    .activity_reserved_family_row {
        padding-right: 22px;
    }
    .reglas .logo-signin {
        margin: 20px auto 0;
        width: 190px;
    }
    .misreservas .btn-card-cancelar {
        padding: 3px 10px;
        margin-top: 18px;
        width: 100%;
    }  

    .modal.show .modal-dialog {
        width: 98% !important;
    } 


    .historial i.fas.fa-users {
        float: left;
        background: transparent;
    }   





    .col_activity_time {
        position: absolute;
        height: 30px;
        top: -16px;
        right: 12px;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col_btn_reservar {
        position: absolute;
        height: 30px;
        right: 50px;
        top: 12px;
    }  
    .activity_time {
        font-size: 16px;
    }    
    .activity_title_row {
        margin-left: 0;
    }    
    .isotipo {
        height: 28px;
        margin-right: 4px;
        margin-top: -2px;
    }   
    .modal.show .modal-dialog {
        width: 98%;
    } 

  .modal-dialog .activity_title {
      margin-left: 18px;
  }
  .confirmar_reservas li {
      margin-right: 8px;
      width: 152px;
  }

    .col_misreservas_btn {
        padding-right: 1px;
        padding-left: 6px;
    }
    .misreservas .btn-card-cancelar {
        padding: 3px 7px;
    }    

  .dropdown_icon_global {
      float: initial !important;
  }      

  .misreservas .dropdown_icon{
      width: 100%;
      display: -ms-grid;
      display: grid;
  } 
    .col_activity_info {
        min-height: 72px;
    }    

  .advsearch_col input[type=checkbox] {
      margin-right: 4px;
      height: 22px;
      width: 22px;
  }
  .advsearch_col .list-inline {
      justify-content: space-between;
  }

  .advsearch_container .btn-md {
      font-size: 18px;
      padding: 7px 12px;
      width: 50% !important;
  }


  .container_group_icons .col-lg-3 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
  .container_icons .image_icons {
      width: 110px;
  }


.dropdown {
    position: absolute;
    right: 0;
}
.message .btn-card-reservar {
    padding: 2px 6px !important;
    margin-top: 18px !important;
    width: 86% !important;
}
.dropdown-menu.show {
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 80%;
}
.dropdown-item {
    font-size: 17px;
}

  .modal_profile p {
      margin-top: 14px!important;
      margin-bottom: 0 !important;
      font-size: 17px !important;
  }
  .modal_profile  .col-sm-4{
    padding-left: 4px;
    padding-right: 4px;
  }
  .btn-profile {
      width: 100%;
      display: list-item;
      text-align: center;
      margin-bottom: 10px;
      padding: 1px 8px;
  }  
  .profile_image_container {
      margin-top: -6px;
  } 
  header .profile_image_container {
      left: -10px;
  }   
  .btn-signin {
      margin-top: 38px !important;
  }
  .forgot-password {
      margin-top: -113px !important;
  }


  .profile_edit_title {
      text-align: left;
      line-height: 1.25em;
      min-height: 30px;
  }  


  .container_profile_edit_files {
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  } 
  .profile_edit_title {
    margin-bottom: -8px;
  } 
  .col_profile_files {
      margin-bottom: 16px;
  }
  .alert_container {
      width: 118%!important;
      margin: 0 -29px!important;
      margin-top: -16px !important;
  }
  .alert {
      padding: .75rem 0;
      margin-bottom: 1rem;
      border: 1px solid transparent;
      border-radius: .25rem;
  }
  .alert .inner {
      display: block;
      padding: 16px 24px;
      margin: 6px 0px 32px;
      border-radius: 3px;
      border: 1px solid #fff3cd;
      background-color: #fff3cd;
  }
  .alert_container  h4 {
      font-size: 19px;
  } 
  .alert_container  p {
      margin-bottom: 3px;
  }


  .menu_user_name span {
      color: #ffffff;
  }



  .routine {
      padding: 0!important;
      padding-top: 2em !important;
  }
  .card_routine {
      margin-left: 0 !important;
  }
  .card_routine .header_routine h1 {
      font-size: 25px;
      padding-top: 10px;
  }  
  .card_routine .body_routine .body_container_content {
      padding: 34px 0px 14px;
  }
  .card_routine .body_routine .body_container_content ul {
      margin-left: 6px;
  }
  .btn_routine_filter_container {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 6px;

  }
  .btn_routine_filter {
      font-size: 11px;
  }
  .routine .footer_section_icons {
      margin-left: 0;
  }
  .card_routine .body_routine h5 {
      font-size: 32px;
      padding: 28px 0 6px;
  } 
  .card_routine .body_routine .body_title_routine h4 {
      font-size: 26px;
  }    
  .title_activity_routine {
      font-size: 20px;
  }  
  .modal.show .modal-dialog.modal-lg {
      width: 98% !important;
      top: 48% !important;
  }  
  .modal.show .modal-dialog.modal-md {
      position: relative;
      transform: none!important;
      left: unset;
      top: 5%!important;
      margin: 0 auto !important;
      width: 98%;
      margin-bottom: 80px !important;
  }
  .horarios_mobile {
      display: block;
  }
  .horarios_web{
      display: none;
  }


  .card .col-7 {
      display: inline-grid;
  }
  .new_activity_time {
      width:100%;
  }
  .new_btn_reservar {
      width: 100%;
  }
  .btn-card-reservar {
      margin-top: 10px !important;
  } 
  .activity_reserved_family_row {
    padding-top: 10px;
  }
}
















@media only screen and (max-width: 375px) {
    body {
            overflow-x: hidden;
    }
    .accordion>label {
        height: 32px;
        line-height: 38px;
    }
    .dropdown-menu {
        /* left: 0px !important; */
        width: 375px;
        position: absolute;
        right: 0px !important;
    }
    .header_right {
        display: inline-flex;
        width: 100%;
        background-color: #081d3a;
    }  
    .page-section {
        padding: 1em!important;
        padding-top: 1.5em !important;
    }
    .container, .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
    .activity_card_none .activity_title {
        font-size: 17px;
    }   
    .activity_occupancy {
        font-size: 13px;
        line-height: 1em;
    }
    .activity_time {
        margin-top: 20px;
        color: #989898;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }
    .btn_editar {
        position: absolute;
        left: 282px;
        font-size: 18px;
        padding: 7px!important;
        width: 43px!important;
    }
   

    .col_time {
        padding-right: 10px;
        padding-left: 10px;
    }    
    .btn-signin {
        margin-top: 24px;
    }
    .forgot-password {
        margin-top: -100px;
    }
    .logo-signin {
        margin: 30px auto 0;
    }    
    .modal_info img.image_icons {
        margin-bottom: 74px;
    }


    .accordion>section p{
        font-size: 11px;
    }      

    .dropdown {
        margin-top: 4px !important;
    }
    .header_right {
        height: 50px;
    }
    .dropdown {
        margin-top: 4px!important;
    }

    .footer_section_icons, .footer {
        margin-left: 0px;
    }
    .col_activity_time {
        position: absolute;
        height: 30px;
        top: -16px;
        right: 12px;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col_btn_reservar {
        position: absolute;
        height: 30px;
        right: 50px;
        top: 12px;
    }
    .activity_time {
        font-size: 15px;
    }
    .activity_place {
        line-height: 1.1em;
        margin: 4px auto;
    }



    .modal-open .col_activity_info {
        padding-right: 0;
        -ms-flex: 0 0 40.33333%;
        flex: 0 0 42.33333%;
        max-width: 42.33333%;
    }
    .modal-dialog .activity_place {
        font-size: 14px;
    }
    .modal-dialog .activity_type {
        font-size: 16px;
    }
    .btn_reservar {
        margin-top: 15px;
    }
    .btn-card-reservar {
        margin-top: 18px;
        width: 100%;
    }
    .btn_covid {
        width: 50%;
        margin: 10px auto 4px;
        font-size: 14px !important;
    }
    .modal-header h3 {
        font-size: 22px;
    }   
    .page-frontcategorias .container_group_icons {
        padding-left: 20px;
        padding-right: 20px;
    }     
  
    .modal_info .col-sm-4 {
        margin-bottom: 16px;
    }     
    .activity_reserved_family_row {
        padding-right: 22px;
    }    

    .misreservas .btn-card-cancelar {
        padding: 2px 6px;
        font-size: 11px;
    }








  .col_activity_time, .col_btn_reservar {
      padding-left: 0px;
      padding-right: 0px;
  } 
  .col_activity_time {
      top: -12px;
      right: 12px;
  }        
  .col_btn_reservar {
      top: 14px;
  }  
  .btn_modal_cancelar, .btn_modal_confirmar {
      margin: 0 28px!important;
  }
  .confirmar_reservas li {
      margin-right: 8px;
      width: 135px;
  }
    .col_activity_info {
        min-height: 85px;
    } 

  .home .footer_section_icons, .horarios footer {
      margin-left: -1em;
  }
 

.message .btn-card-reservar {
        margin-top: 10px;
    font-size: 10px;
        padding: 4px;
}
.mensaje_titulo {
    padding-bottom: 10px;
}  

  .modal_profile .col-sm-4 img {
      max-height: 64px !important;
  }  
  header .profile_image_container {
      left: -14px;
  }
  .home .footer_section_icons, .horarios footer {
      margin-left: -14px;
  }  

  .btn_filters_message, .btn_filters_message_selected {
      max-width: 85px;
      margin-left: 5px;
      margin-right: 5px;
  }
}


















@media only screen and (max-width: 360px) {

    .navbar-brand img {
        max-height: 48px!important;
        margin-top: 4px;
        margin-left: 0;
        width: auto!important;
    }
    .navbar-header {
        height: 70px;
        margin: 0 auto;
    }
    .dropdown {
        margin-top: 4px!important;
        float: right;
    }
    .header_right {
        height: 50px;
    }
    .dropdown {
        margin-top: 4px!important;
    }
    .btn_reservar {
        margin-top: 15px;
        margin-right: 10px;
    }
    nav#nav {
        border-bottom: 6px solid #fff!important;
    }
    .logo-signin {
        height: auto;
        display: block;
        margin: 24px auto 0;
        width: 220px;
    }
    .accordion>section p, .tab-container .tab {
        font-size: 11px;
    }   
    .col_welcome_left, .col_welcome_right {
        -ms-flex: 0 0 50%;
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    } 
    .container_signup h1, .container_welcome h1 {
        color: #fff;
        font-size: 21px;
    }      
    .container_signup p, .container_welcome p {
        color: #fff;
        font-size: 13px;
        line-height: 1.2em;
    } 
    .col_welcome_right {
        padding-top: 0;
    }       
    .accordion>label {
        margin-bottom: 0;
    } 

    .tab-container .tab {
        line-height: 28px;
    }     
    .tab-container .tab-content-container {
        top: 28px;
    }
    .dropdown-menu {
        /* left: 0px !important; */
        width: 360px;
        position: absolute;
        right: 0px !important;
    }
    .accordion>label {
        height: 38px;
        line-height: 38px;
    }
    .activity_today_date {
        font-size: 14px;
    }    
    .activity_btn_back, .activity_tomorrow_date {
        font-size: 13px;
    } 
    .btn-signin {
        margin-top: 26px;
        font-size: 14px;
        padding: 6px 20px;
    } 
    .forgot-password {
        margin-top: -103px;
    }    
    .btn-card-reservar {
        font-size: 11px;
        float: right;
    }    
    .activity_card{
        padding-left: 10px;
        padding-right: 10px;
    }      
    .fa-edit {
        font-size: 16px;
    }
    .btn_editar {
        padding: 9px!important;
        width: 38px!important;
        top: 4px;
    }  
    .accordion b {
        font-size: 9px;
    }    

    .bg-header {
        height: 90px;
        padding: 28px 10px 10px 24px;
    }    
    .activity_card_none .activity_title, .activity_title {
        font-size: 17px;
    }      
    .activity_card_none .activity_title {
        font-size: 16px;
    }
    .activity_place {
        font-size: 13px;
    } 
    .bg-header h1 {
        font-size: 24px;
    }

    .fa-user {
        padding-right: 0px;
        font-size: 20px;
        color: cyan;
    }
    .menu_user_name {
        padding-left: 4px;
        border-left: none;
    }

    .container_reservar_ya {
        float: left;
        margin-left: 16px;
    }
    button#single-button.btn_menu {
        margin-right: 12px;
        padding: 0;
        padding-top: 10px;
        padding-left: 10px;
    }
    .activity_card_none .activity_title {
        font-size: 17px !important;
    }
    .chevron {
        margin-top: 0px;
        font-size: 21px;
    }
    .btn_editar {
        padding: 9px!important;
        width: 38px!important;
        top: 4px;
        left: 272px;
    }
    .modal-header h3 {
        font-size: 20px;
    }        
    .modal-header {
        padding-bottom: 8px;
        padding-top: 8px;
    }
    .select_global, .select_history {
        padding: 1px 32px 1px 8px!important;
    }
    .dropdown_icon_global:before, .dropdown_icon_right:before {
        top: -1px;
    }
    .row_invitados .col-sm-4 {
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-bottom: 4px;
        margin-top: 2px;
    }
    .row_invitados span {
        margin-top: -4px;
        margin-left: 4px;
        width: 77%;
    } 
    .btn_modal_cancelar, .btn_modal_confirmar {
        font-size: 13px !important;
        margin: 0 22px!important;
    }
     .modal_info img.image_icons {
        margin-bottom: 90px;
    }   

    .modal-dialog.modal-lg p {
        line-height: 1.35em;
        font-size: 11px;
        margin-bottom: 22px;
    }    

    .dropdown_icon:before {
        top: -1px;
    }

    .modal_info .col-sm-4 {
        margin-bottom: 8px;
    }    
    .col_misreservas_btn {
        padding-right: 4px;
        padding-left: 8px;
    } 
    .misreservas .activity_time {
        font-size: 13px;
    }    



    .confirmar_reservas li {
        margin-right: 8px;
        width: 128px;
    }  

  header .profile_image_container {
      width: 31px;
      height: 31px;
      left: -28px;
  }     


  .modal.show .modal-dialog.modal-lg h5, .modal.show .modal-dialog.modal-lg p, .modal.show .modal-dialog.modal-lg h4 {
      font-size: .9rem;
  }
}












@media only screen and (max-width: 320px) {
  .navbar-brand img{
  max-height:30px;
  }
  .navbar-brand h4{
    font-size: 11px;
    line-height: 30px;
  }
    .logo-signin {
        margin: 0 auto;
        width: 210px;
        margin-top: 16px;
        width: 200px;
    }
    .accordion>label {
        height: 34px;
        line-height: 34px;
    }
    .btn_reservar p {
        color: #fff;
        font-size: 16px;
        display: block;
        margin-top: 2px;
    }
    .menu_user_name {
        padding-left: 4px;
        font-size: 14px;
        border-left: none;
    }
    .fa-user {
        padding-right: 0px;
        font-size: 17px;
    }
    .btn_reservar .fa-calendar-alt {
        margin-right: 10px;
    }
    .btn_menu {
        padding: 10px 10px;
        padding-right: 4px;
    }

    .bg-header {
        height: 90px;
        padding: 32px 10px 10px 24px;
    }.bg-header h1 {
        font-size: 22px;
    }  
    .dropdown-menu {
        /* left: 0px !important; */
        width: 320px;
        position: absolute;
        right: 0px !important;
    }
    .activity_btn_back, .activity_tomorrow_date {
        font-size: 12px;
    }    
    .activity_today_date {
        font-size: 13px;
    }   
    .btn_editar {
        left: 242px;
    }   
    .activity_title {
        font-size: 16px;
    }
    .history_canceled, .history_reserved {
        font-weight: 500;
        color: #002028;
        font-size: 13px;
    }
    .col_misreservas_btn {
        padding-right: 0;
    }
    .activity_time {
        font-size: 14px;
    }  
    .btn-card-reservar {
        font-size: 10px;
    }    
    .activity_place {
        font-size: 13px;
    }
    .activity_occupancy {
        font-size: 11px;
        margin-top: 2px;
    }
    .modal_info img.image_icons {
        display: none;
    }
    .modal_info .col-sm-4.px-4:last-child {
        margin-top: 0px;
    }
    .modal-dialog.modal-lg p {
        line-height: 1.25em;
        font-size: 11px;
        margin-bottom: 22px;
    }    
    .container_signup p, .container_welcome p {
        color: #fff;
        font-size: 12px;
        line-height: 1.2em;
    }
    .container_signup h1, .container_welcome h1 {
        color: #fff;
        font-size: 20px;
        line-height: 1.1em !important;
    }
    .container_reservar_ya {
        float: left;
        margin-left: 18px;
    }   
    .btn_reservar .fa-calendar-alt {
        font-size: 20px;
        margin-right: 8px;
    }     
    i.fas.fa-users {
        display: none;
    } 
    .activity_reserved_family_row {
        margin-left: 4px;
    }    
    .activity_time {
        font-size: 14px;
        margin-top: 22px;
    }   
    .row_invitados span {
        margin-top: -4px;
        margin-left: 4px;
        width: 73%;
    }    
    .modal-dialog .activity_time {
        font-size: 16px;
    }
    .modal-dialog .activity_type {
        font-size: 15px;
    }
    .modal-dialog .activity_place {
        font-size: 13px;
    }
    .modal-dialog .activity_time {
        font-size: 16px;
        margin-top: 30px;
    }    
    .icon_exclamation {
        width: 80px;
    }   
    .form-group.input-group-lg.mb-10.dropdown_icon.ng-scope {
        width: 100%;
        display: grid;
    }
    .select_history {
        padding: 3px 32px 3px 8px!important;
    }

    .dropdown_icon:before {
        top: 0px;
    } 



    .row.row_invitados {
        overflow: scroll;
        height: 190px;
    }
    .confirmar_reservas li {
        margin-right: 0;
        width: 100%;
    }           

  .mensaje_dia_hora {
      font-size: 11px;
  }
}
